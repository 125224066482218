import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-lg font-semibold leading-6 text-gray-900" }
const _hoisted_2 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_3 = { class: "mt-6 flex space-x-3" }
const _hoisted_4 = { class: "underline" }

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { isLeft } from '@/lib/either';
import { useUserStore } from '@/stores/user';
import { User } from '@/models/user.model';
import StatusMessage from '@/components/Form/StatusMessage.vue';
import ConfirmDialog from '@/components/Core/ConfirmDialog.vue';
import Button from '@/components/Core/Button.vue';
import { AppError, ErrorCode } from '@/lib/error';

interface Props {
  user: User;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserDeleteForm',
  props: {
    user: {}
  },
  setup(__props: any) {

const userStore = useUserStore();
const { t } = useI18n();

const props = __props;

const loading = ref(false);
const showConfirm = ref(false);
const errorMessage = ref<string>();

const handleConfirm = async (result: boolean) => {
  showConfirm.value = false;
  loading.value = true;
  errorMessage.value = undefined;

  if (!result) {
    return;
  }

  const res = await userStore.deleteUser(props.user.id, props.user.email);
  const err = isLeft(res) ? res.left : null;

  switch (AppError.getCode(err)) {
    case null:
      break;
    case ErrorCode.Conflict:
      errorMessage.value = t('profile.deactivation.error.lastAdmin');
      break;
    default:
      errorMessage.value = t('common.error.tech');
      break;
  }

  loading.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('profile.deactivation.title')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('profile.deactivation.text')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(Button, {
        class: "btn-sm btn-red",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (showConfirm.value = true)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (showConfirm.value = true), ["enter"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('profile.deactivation.btn')), 1)
        ]),
        _: 1
      }),
      _createVNode(StatusMessage, {
        show: !!errorMessage.value,
        type: "error"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(errorMessage.value), 1)
        ]),
        _: 1
      }, 8, ["show"])
    ]),
    _createVNode(ConfirmDialog, {
      show: showConfirm.value,
      onResult: handleConfirm
    }, {
      title: _withCtx(() => [
        _createVNode(_component_i18n_t, {
          keypath: "profile.deactivation.confirm.title",
          tag: "span"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.user.email), 1)
          ]),
          _: 1
        })
      ]),
      text: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('profile.deactivation.confirm.text')), 1)
      ]),
      confirm: _withCtx(() => [
        _createVNode(Button, { class: "w-full sm:w-auto" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('profile.deactivation.confirm.btn')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})