import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold flex items-center" }
const _hoisted_2 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_3 = { class: "flex items-center mt-6 w-full space-x-9" }
const _hoisted_4 = { class: "flex items-center space-x-3 w-full sm:w-1/2" }
const _hoisted_5 = { class: "flex -space-x-2.5 overflow-hidden" }
const _hoisted_6 = { class: "text-gray-600 font-medium" }
const _hoisted_7 = {
  key: 0,
  class: "hidden sm:block sm:w-1/2"
}
const _hoisted_8 = { class: "h-10 w-10 p-1.5 rounded-full border-2 border-dashed border-gray-300 group-hover/invite-member:border-green-500" }
const _hoisted_9 = { class: "text-gray-600 group-hover/invite-member:text-gray-900" }
const _hoisted_10 = { class: "mt-7" }

import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { PlusIcon, EllipsisHorizontalIcon } from '@heroicons/vue/24/outline';
import Avatar from '@/components/User/Avatar.vue';
import Button from '@/components/Core/Button.vue';
import { computed } from 'vue';

const USERS_TO_SHOW = 3;


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardTeam',
  setup(__props) {

const { t } = useI18n();
const router = useRouter();
const userStore = useUserStore();

const userList = userStore.sortedUserList;
const usersCount = computed(() => userList.length);
const isAdmin = computed(() => userStore.currentUser.isAdmin());

const avatarsCount = computed(() => {
  if (userList.length > USERS_TO_SHOW) {
    return USERS_TO_SHOW;
  }

  return userList.length;
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_unref(t)('page.dashboard.team.title')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)('page.dashboard.team.text')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(avatarsCount.value, (i) => {
            return (_openBlock(), _createBlock(Avatar, {
              key: i,
              size: "md",
              class: "ring-2 ring-white",
              seed: _unref(userList)[i - 1].id,
              userName: _unref(userList)[i - 1].name || _unref(userList)[i - 1].email
            }, null, 8, ["seed", "userName"]))
          }), 128)),
          (usersCount.value > USERS_TO_SHOW)
            ? (_openBlock(), _createBlock(Avatar, {
                key: 0,
                size: "md",
                class: "ring-2 ring-white",
                seed: `${'*'.repeat(usersCount.value - USERS_TO_SHOW)}`,
                icon: _unref(EllipsisHorizontalIcon)
              }, null, 8, ["seed", "icon"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('page.dashboard.team.members', { count: usersCount.value })), 1)
      ]),
      (isAdmin.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_router_link, {
              to: "/team",
              class: "flex space-x-3 items-center group/invite-member"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_unref(PlusIcon), { class: "h-6 w-6 stroke-gray-300 stroke-2 group-hover/invite-member:stroke-green-500" })
                ]),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('page.dashboard.team.invite')), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(Button, {
        class: "btn-sm btn-bordered",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/team')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(isAdmin.value ? _unref(t)('page.dashboard.team.btn') : _unref(t)('common.btn.viewMore')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})