import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "mt-7 text-center text-gray-500 text-sm" }
const _hoisted_2 = { class: "text-sm text-center mt-8" }

import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { AppError, ErrorCode } from '@/lib/error';
import { AuthRouteName } from '@/router/route.names';
import AuthPage from '@/components/Auth/AuthPage.vue';
import Button from '@/components/Core/Button.vue';
import Loader from '@/components/Core/Loader.vue';

const RESEND_TIMEOUT = 45000;


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordStep2',
  setup(__props) {

const { t } = useI18n({ useScope: 'global' });
const authStore = useAuthStore();

const loading = ref(false);
const allowResend = ref(false);
const formError = ref<string>();

const setResendTimeout = () => {
  allowResend.value = false;

  setTimeout(() => {
    allowResend.value = true;
  }, RESEND_TIMEOUT);
};

const handleResend = async (): Promise<void> => {
  if (loading.value) {
    return;
  }

  loading.value = true;
  const email = authStore.lastEmail!;
  const err = await authStore.startPasswordReset(email);

  switch (AppError.getCode(err)) {
    case null:
      setResendTimeout();
      break;
    case ErrorCode.Unauthorized:
    case ErrorCode.Invalid:
      formError.value = t('auth.resetPassword.step1.error.email');
      break;
    default:
      formError.value = t('common.error.tech');
      break;
  }

  loading.value = false;
};

onMounted(() => setResendTimeout());

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(AuthPage, null, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.step2.title')), 1)
    ]),
    form: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('auth.resetPassword.step2.text')), 1),
      _createVNode(Button, {
        class: "w-full mt-9",
        disabled: !allowResend.value,
        onClick: handleResend
      }, {
        default: _withCtx(() => [
          (loading.value)
            ? (_openBlock(), _createBlock(Loader, {
                key: 0,
                class: "text-white"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.step2.btn')), 1)
              ], 64))
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: { name: _unref(AuthRouteName).Login },
          class: "text-gray-600 hover:underline font-medium"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.returnToSignIn')), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }))
}
}

})