import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-10 divide-y divide-gray-200 grid grid-cols-1 lg:grid-cols-6" }
const _hoisted_2 = { class: "col-span-6 lg:col-span-5 xl:col-span-4" }
const _hoisted_3 = { class: "text-xl font-semibold flex items-center" }
const _hoisted_4 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_5 = { class: "mt-8 grid grid-cols-6 gap-x-8 gap-y-8 lg:gap-x-9" }
const _hoisted_6 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_7 = { class: "w-auto flex flex-1 flex-col justify-between items-center rounded-xl p-6 border" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = { class: "mt-2 text-base sm:text-lg font-medium" }
const _hoisted_10 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_11 = { class: "w-auto flex flex-1 flex-col justify-between items-center rounded-xl p-6 border" }
const _hoisted_12 = { class: "text-center" }
const _hoisted_13 = { class: "mt-2 text-base sm:text-lg font-medium" }

import { useI18n } from 'vue-i18n';
import { useAnalytics } from '@/composables/analytics';
import Button from '@/components/Core/Button.vue';
import PageContent from '@/components/Layout/PageContent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadsPage',
  setup(__props) {

const { t } = useI18n();
const analytics = useAnalytics();

const handleChromeClick = () => {
  analytics.event('add_extension_click', { source: 'downloads' });
  window.open(process.env.VUE_APP_EXTENSION_URL, '_blank')?.focus();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageContent, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_unref(t)('page.downloads.hero.title')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)('page.downloads.hero.text')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[0] || (_cache[0] = _createElementVNode("img", {
                    class: "w-16 h-16 sm:w-20 sm:h-20 md:w-28 md:h-28 mx-auto",
                    src: "/icons/browser-chrome.svg",
                    alt: "Chrome"
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(t)('page.downloads.chrome.title')), 1)
                ]),
                _createVNode(Button, {
                  class: "w-full mt-4 sm:mt-6",
                  onClick: handleChromeClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('page.downloads.btn.download')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    class: "w-16 h-16 sm:w-20 sm:h-20 md:w-28 md:h-28 mx-auto",
                    src: "/icons/browser-edge.svg",
                    alt: "Edge"
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(t)('page.downloads.edge.title')), 1)
                ]),
                _createVNode(Button, {
                  disabled: "",
                  class: "w-full btn-bordered mt-4 sm:mt-6"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('page.downloads.btn.soon')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})