import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center min-h-screen py-6 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "flex w-full h-full items-center justify-center" }

import { onMounted } from 'vue';
import { ErrorCode } from '@/lib/error';
import { useAuthStore } from '@/stores/auth';
import Loader from '@/components/Core/Loader.vue';

interface Props {
  newUser: boolean;
  error?: ErrorCode;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthStatusPage',
  props: {
    newUser: { type: Boolean },
    error: {}
  },
  setup(__props: any) {

const authStore = useAuthStore();
const props = __props;

onMounted(async () => {
  await authStore.handleProviderAuth(props.newUser, props.error);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Loader)
    ])
  ]))
}
}

})