import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from './cherrytree_light.svg'
import _imports_1 from './cherrytree_dark.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "Cherrytree",
  class: "w-auto pointer-events-none"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: "Cherrytree",
  class: "w-auto pointer-events-none"
}



interface Props {
  theme?: 'light' | 'dark';
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Logo',
  props: {
    theme: { default: 'light' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.theme === 'light')
    ? (_openBlock(), _createElementBlock("img", _hoisted_1))
    : (_openBlock(), _createElementBlock("img", _hoisted_2))
}
}

})