import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';

interface Props {
  type: 'alert' | 'warning'
  message: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SubscriptionNotice',
  props: {
    type: { default: 'warning' },
    message: { default: '' }
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'flex items-center w-auto text-xs',
      props.type === 'alert' ? 'text-red-700' : 'text-yellow-700',
    ])
  }, [
    _createVNode(_unref(ExclamationCircleIcon), {
      class: _normalizeClass([
        'h-4 w-4 stroke-2 mr-1.5 shrink-0',
        props.type === 'alert' ? 'stroke-red-700' : 'stroke-yellow-700',
      ])
    }, null, 8, ["class"]),
    _createElementVNode("span", null, _toDisplayString(props.message), 1)
  ], 2))
}
}

})