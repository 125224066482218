import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Switch } from '@headlessui/vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'Switch',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Switch), { class: "switch" }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("span", {
        class: "switch-circle",
        "aria-hidden": "true"
      }, null, -1)
    ])),
    _: 1
  }))
}
}

})