import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "relative flex justify-start" }
const _hoisted_3 = { class: "bg-white pr-2 text-xs text-gray-600" }
const _hoisted_4 = { class: "text-sm text-gray-800 mt-3 space-y-2" }
const _hoisted_5 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_6 = {
  key: 0,
  class: "font-normal text-xs text-gray-600"
}
const _hoisted_7 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_8 = {
  key: 0,
  class: "font-normal text-xs text-gray-600"
}
const _hoisted_9 = { class: "flex justify-between text-base font-semibold" }
const _hoisted_10 = {
  key: 0,
  class: "font-normal text-xs text-gray-600"
}

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Currency, Money } from '@/lib/money';

interface Props {
  title: string;
  withDetails: boolean;
  unitAmount: number;
  unitHint?: string;
  subtotalAmount: number;
  subtotalHint?: string;
  totalAmount: number;
  totalHint?: string;
  trialDays: number;
  currency: Currency;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentDetails',
  props: {
    title: {},
    withDetails: { type: Boolean },
    unitAmount: {},
    unitHint: {},
    subtotalAmount: {},
    subtotalHint: {},
    totalAmount: {},
    totalHint: {},
    trialDays: {},
    currency: {}
  },
  setup(__props: any) {

const { t } = useI18n();
const props = __props;

const isTrial = computed(() => props.trialDays > 0);

const formatPrice = (amount: number): string => {
  if (amount < 0) {
    return '–';
  }

  const price = new Money(amount, props.currency);
  return price.toString();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "absolute inset-0 flex items-center",
        "aria-hidden": "true"
      }, [
        _createElementVNode("div", { class: "w-full border-t border-gray-300" })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (props.withDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_unref(t)('billing.price.details.unitAmount')) + " ", 1),
              (props.unitHint)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, " (" + _toDisplayString(props.unitHint) + ") ", 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, _toDisplayString(formatPrice(props.unitAmount)), 1)
          ]))
        : _createCommentVNode("", true),
      (props.withDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createTextVNode(_toDisplayString(_unref(t)('billing.price.details.subtotal')) + " ", 1),
              (props.subtotalHint)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, " (" + _toDisplayString(props.subtotalHint) + ") ", 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, _toDisplayString(formatPrice(props.subtotalAmount)), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        (isTrial.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", null, _toDisplayString(_unref(t)('billing.price.details.trial', { days: props.trialDays })), 1),
              _createElementVNode("div", null, _toDisplayString(_unref(t)('billing.price.details.free')), 1)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_unref(t)('billing.price.details.total')) + " ", 1),
                (props.totalHint)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, " (" + _toDisplayString(props.totalHint) + ") ", 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", null, _toDisplayString(formatPrice(props.totalAmount)), 1)
            ], 64))
      ])
    ])
  ]))
}
}

})