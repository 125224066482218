import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString } from "vue"

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserRole } from '@/models/user.model';

interface Props {
  role: UserRole;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserRoleLabel',
  props: {
    role: { default: UserRole.User }
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const roleLabel = computed(() => {
  switch (props.role) {
    case UserRole.Admin:
      return t('user.role.admin');
    case UserRole.User:
    default:
      return t('user.role.user');
  }
});

return (_ctx: any,_cache: any) => {
  return _toDisplayString(roleLabel.value)
}
}

})