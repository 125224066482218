import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-1 space-y-1 px-3" }

import { FunctionalComponent } from 'vue';
import { useRoute } from 'vue-router';

interface NavItem {
  name: string;
  icon: FunctionalComponent;
  href: string;
}

interface Props {
  navigation: NavItem[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarMenu',
  props: {
    navigation: {}
  },
  emits: ['navItemClick'],
  setup(__props: any) {

const currentRoute = (href: string): boolean => {
  const route = useRoute();
  return route.path === href;
};




return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
      return (_openBlock(), _createBlock(_component_router_link, {
        to: item.href,
        key: item.name,
        class: _normalizeClass([
        currentRoute(item.href) ?
        'bg-coal-800 text-white' :
        'text-coal-300 hover:bg-coal-800 hover:text-white',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
      ]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('navItemClick')))
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
            class: _normalizeClass([
          currentRoute(item.href) ?
          'stroke-green-500' :
          'stroke-gray-400 group-hover:stroke-gray-400',
          'mr-3 flex-shrink-0 h-6 w-6'
        ]),
            "aria-hidden": "true"
          }, null, 8, ["class"])),
          _createTextVNode(" " + _toDisplayString(item.name), 1)
        ]),
        _: 2
      }, 1032, ["to", "class"]))
    }), 128))
  ]))
}
}

})