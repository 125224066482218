import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold flex items-center" }
const _hoisted_2 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_3 = { class: "grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-8 lg:gap-x-9 items-center mt-8" }

import { useI18n } from 'vue-i18n';
import { useAnalytics } from '@/composables/analytics';
import ShortcutCard from '@/components/Dashboard/ShortcutCard.vue';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  PuzzlePieceIcon,
} from '@heroicons/vue/24/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardWelcome',
  setup(__props) {

const { t } = useI18n();
const analytics = useAnalytics();

const extensionUrl = process.env.VUE_APP_EXTENSION_URL;
const chatgptUrl = process.env.VUE_APP_CHATGPT_URL;

const trackClick = (eventName: string) => {
  analytics.event(eventName, { source: 'dashboard' });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, [
      _createTextVNode(_toDisplayString(_unref(t)('page.dashboard.hero.title')) + " ", 1),
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: "/icons/waving-hand.svg",
        alt: "icon",
        class: "w-5 h-5 ml-1.5"
      }, null, -1))
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)('page.dashboard.hero.text')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", null, [
        _createVNode(ShortcutCard, {
          class: "w-auto h-40",
          title: _unref(t)('page.dashboard.shortcut.extension.title'),
          text: _unref(t)('page.dashboard.shortcut.extension.text'),
          icon: _unref(PuzzlePieceIcon),
          link: _unref(extensionUrl),
          background: "linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%)",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (trackClick('add_extension_click')))
        }, null, 8, ["title", "text", "icon", "link"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(ShortcutCard, {
          class: "col-span-1 w-auto h-40",
          title: _unref(t)('page.dashboard.shortcut.chatgpt.title'),
          text: _unref(t)('page.dashboard.shortcut.chatgpt.text'),
          icon: _unref(ChatBubbleOvalLeftEllipsisIcon),
          link: _unref(chatgptUrl),
          background: "linear-gradient(to left, #43cea2, #185a9d)",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (trackClick('open_chatgpt_click')))
        }, null, 8, ["title", "text", "icon", "link"])
      ])
    ])
  ]))
}
}

})