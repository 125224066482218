import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-1"
}
const _hoisted_2 = { class: "ml-1" }

import {
  computed,
  ref,
  watch, onMounted,
} from 'vue';
import { CheckIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline';

interface Props {
  type: 'success' | 'error';
  show: boolean;
  timeout?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusMessage',
  props: {
    type: { default: 'success' },
    show: { type: Boolean },
    timeout: { default: 2700 }
  },
  setup(__props: any) {

const props = __props;

const hide = ref(false);
const visible = computed(() => props.show && !hide.value);
const timer = ref<number>();

const autoHide = () => {
  if (!timer.value) {
    hide.value = false;

    timer.value = setTimeout(() => {
      hide.value = true;
      timer.value = undefined;
    }, props.timeout);
  }
};

onMounted(() => {
  if (props.show) {
    autoHide();
  }
});

watch(() => props.show, (val, prevVal) => {
  if (val && !prevVal) {
    autoHide();
  }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex text-xs items-center transition duration-300 ease-in-out opacity-0", {
    'opacity-100': visible.value,
    'text-green-600': _ctx.type === 'success',
    'text-red-600': _ctx.type === 'error',
  }])
  }, [
    (_ctx.type === 'success')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_unref(CheckIcon), { class: "h-4 w-4" }),
          (_ctx.type === 'success')
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                _renderSlot(_ctx.$slots, "default", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.message.changesSaved')), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_unref(ExclamationCircleIcon), { class: "h-4 w-4" }),
          _createElementVNode("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.$t('common.error.tech')), 1)
            ])
          ])
        ], 64))
  ], 2))
}
}

})