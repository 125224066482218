import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = { class: "text-xs mt-1" }

import { FunctionalComponent } from 'vue';

interface Props {
  title: string;
  text: string;
  link: string;
  icon: FunctionalComponent;
  background: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ShortcutCard',
  props: {
    title: {},
    text: {},
    link: {},
    icon: { type: [Function, Object] },
    background: {}
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.link,
    target: "_blank",
    class: "flex flex-col justify-between rounded-xl p-4 text-white hover:opacity-95",
    style: _normalizeStyle({ background: props.background }),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('click')))
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(props.icon), {
        "aria-hidden": "true",
        class: "stroke-white w-6 h-6"
      }))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(props.title), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(props.text), 1)
    ])
  ], 12, _hoisted_1))
}
}

})