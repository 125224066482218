import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "border rounded-xl shadow-lg" }
const _hoisted_2 = { class: "grid grid-cols-6 gap-x-1 px-5 py-4 bg-gray-50 rounded-t-xl border-b" }
const _hoisted_3 = { class: "col-span-4 sm:col-span-3" }
const _hoisted_4 = { class: "hidden md:block text-xs text-gray-600" }
const _hoisted_5 = { class: "text-lg font-semibold mt-1.5" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "text-sm font-normal text-gray-600 block md:inline-block md:ml-1.5" }
const _hoisted_9 = { class: "col-span-2 sm:col-span-3" }
const _hoisted_10 = { class: "hidden md:block text-xs text-gray-600" }
const _hoisted_11 = { class: "text-lg font-semibold mt-1.5" }
const _hoisted_12 = { class: "text-sm font-normal text-gray-600 block md:inline-block md:ml-1.5" }
const _hoisted_13 = { class: "pb-3" }
const _hoisted_14 = { class: "px-5 py-4 md:py-5" }
const _hoisted_15 = { class: "text-xs text-gray-600 hidden sm:block mb-3" }
const _hoisted_16 = {
  key: 0,
  class: "flex px-5 mb-3 w-5/6"
}
const _hoisted_17 = { class: "flex space-x-4 px-5 pt-3 pb-2 items-center" }

import {
  computed,
  ref,
  onMounted,
  nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { NotificationType, Plan, Subscription } from '@/models';
import { useUserStore } from '@/stores/user';
import { useBillingStore } from '@/stores/billing';
import { useAccountStore } from '@/stores/account';
import { useNotificationStore } from '@/stores/notification';
import { isLeft } from '@/lib/either';
import { Money } from '@/lib/money';
import SubFeaturesList from '@/components/Billing/Subscription/SubFeaturesList.vue';
import Button from '@/components/Core/Button.vue';
import PlanCalculator from '@/components/Billing/Calculator/PlanCalculator.vue';
import ConfirmDialog from '@/components/Core/ConfirmDialog.vue';
import SubscriptionNotice from '@/components/Billing/Subscription/SubscriptionNotice.vue';

type CalculatorElem = InstanceType<typeof PlanCalculator>;

interface Props {
  showUnsubscribe?: boolean;
  showCalculator?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SubscriptionCard',
  props: {
    showUnsubscribe: { type: Boolean, default: true },
    showCalculator: { type: Boolean, default: false }
  },
  setup(__props: any) {

const { t, d } = useI18n();
const userStore = useUserStore();
const accStore = useAccountStore();
const billingStore = useBillingStore();
const notificationStore = useNotificationStore();

const props = __props;

const calculator = ref<CalculatorElem>();
const mountCalculator = ref(false);
const cancelLoading = ref(false);
const resumeLoading = ref(false);

const subscription = computed(() => billingStore.subscription as Subscription);
const plan = computed(() => billingStore.planById(subscription.value.planId) as Plan);
const account = computed(() => accStore.account);
const usersCount = computed(() => subscription.value.seats);
const isAdmin = computed(() => userStore.currentUser.isAdmin());

const showCancelConfirm = ref(false);

const canUnsubscribe = computed(() => {
  if (!props.showUnsubscribe) {
    return false;
  }

  return subscription.value.isPro() && !subscription.value.willBeCanceled();
});

const showNotice = computed(() => (
  subscription.value.isTrial() || subscription.value.willBeCanceled()
));

const priceText = computed(() => {
  switch (true) {
    case plan.value.isFree():
      return t('billing.subscription.price.free');
    case subscription.value.isTrial():
      return new Money(0, plan.value.currency).toString();
    default: {
      let price = new Money(plan.value.price, plan.value.currency);
      price = price.multiply(usersCount.value);
      return price.toString();
    }
  }
});

const pricePeriod = computed(() => {
  switch (true) {
    case plan.value.isFree():
      return '';
    case subscription.value.isTrial():
      return t('billing.subscription.price.duringTrial');
    default:
      return t('billing.subscription.price.perMonth');
  }
});

const btnText = computed(() => {
  if (plan.value.isFree()) {
    return account.value?.trialUsed
      ? t('billing.subscription.btn.upgradePlan')
      : t('billing.subscription.btn.startTrial');
  }

  return t('billing.subscription.btn.manage');
});

const openCalculator = () => {
  mountCalculator.value = true;
  nextTick(() => {
    calculator.value?.open();
  });
};

const handleResume = async () => {
  resumeLoading.value = true;
  const result = await billingStore.resumeSubscription();
  resumeLoading.value = false;

  if (isLeft(result)) {
    notificationStore.addNotification({
      type: NotificationType.Error,
      title: t('notification.subscriptionNotResumed.title'),
      text: t('notification.subscriptionNotResumed.text'),
    });

    return;
  }

  notificationStore.addNotification({
    type: NotificationType.Success,
    title: t('notification.subscriptionResumed.title'),
    text: t('notification.subscriptionResumed.text'),
  });
};

const handleCancel = async (confirmed: boolean) => {
  showCancelConfirm.value = false;

  if (!confirmed) {
    return;
  }

  cancelLoading.value = true;
  const result = await billingStore.cancelSubscription();
  cancelLoading.value = false;

  if (isLeft(result)) {
    notificationStore.addNotification({
      type: NotificationType.Error,
      title: t('notification.subscriptionNotCanceled.title'),
      text: t('notification.subscriptionNotCanceled.text'),
    });

    return;
  }

  notificationStore.addNotification({
    type: NotificationType.Info,
    title: t('notification.subscriptionCanceled.title'),
    text: t('notification.subscriptionCanceled.text'),
  });
};

onMounted(() => props.showCalculator && openCalculator());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)('billing.subscription.plan.title')), 1),
        _createElementVNode("div", _hoisted_5, [
          (subscription.value.isPro())
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(t)('billing.subscription.plan.pro')), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(t)('billing.subscription.plan.free')), 1)),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(t)('billing.subscription.seats.count', { count: usersCount.value })), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(t)('billing.subscription.price.title')), 1),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", null, _toDisplayString(priceText.value), 1),
          _createElementVNode("span", _hoisted_12, _toDisplayString(pricePeriod.value), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(t)('billing.subscription.feature.title')), 1),
        _createVNode(SubFeaturesList, {
          plan: subscription.value.planId,
          class: "mt-1.5"
        }, null, 8, ["plan"])
      ]),
      (showNotice.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            (subscription.value.willBeCanceled())
              ? (_openBlock(), _createBlock(SubscriptionNotice, {
                  key: 0,
                  type: "alert",
                  message: _unref(t)('billing.subscription.notice.canceled', { date: _unref(d)(subscription.value.cancelAt!, 'base') })
                }, null, 8, ["message"]))
              : (subscription.value.isTrial())
                ? (_openBlock(), _createBlock(SubscriptionNotice, {
                    key: 1,
                    type: "warning",
                    message: _unref(t)('billing.subscription.notice.trial', { date: _unref(d)(subscription.value.periodEnd, 'base') })
                  }, null, 8, ["message"]))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (isAdmin.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_17, [
              (subscription.value.willBeCanceled())
                ? (_openBlock(), _createBlock(Button, {
                    key: 0,
                    class: "btn-sm btn-bordered-green",
                    loading: resumeLoading.value,
                    onClick: handleResume
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(CheckCircleIcon), { class: "w-4 h-4 fill-green-500 mr-1.5" }),
                      _createTextVNode(" " + _toDisplayString(_unref(t)('billing.subscription.btn.resume')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"]))
                : (_openBlock(), _createBlock(Button, {
                    key: 1,
                    class: "btn-sm btn-bordered-green",
                    onClick: openCalculator
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(CheckCircleIcon), { class: "w-4 h-4 fill-green-500 mr-1.5" }),
                      _createTextVNode(" " + _toDisplayString(btnText.value), 1)
                    ]),
                    _: 1
                  })),
              (canUnsubscribe.value)
                ? (_openBlock(), _createBlock(Button, {
                    key: 2,
                    class: "btn-sm btn-bordered",
                    loading: cancelLoading.value,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (showCancelConfirm.value = true))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(t)('common.btn.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"]))
                : _createCommentVNode("", true)
            ]),
            (mountCalculator.value)
              ? (_openBlock(), _createBlock(PlanCalculator, {
                  key: 0,
                  ref_key: "calculator",
                  ref: calculator
                }, null, 512))
              : _createCommentVNode("", true),
            _createVNode(ConfirmDialog, {
              show: showCancelConfirm.value,
              onResult: handleCancel
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('billing.unsubscribe.confirm.title')), 1)
              ]),
              text: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('billing.unsubscribe.confirm.text', {
                periodEnd: _unref(d)(subscription.value.periodEnd, 'short')
              })), 1)
              ]),
              confirm: _withCtx(() => [
                _createVNode(Button, { class: "w-full sm:w-auto" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('billing.subscription.btn.cancel')), 1)
                  ]),
                  _: 1
                })
              ]),
              cancel: _withCtx(() => [
                _createVNode(Button, { class: "w-full sm:w-auto btn-bordered" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('billing.subscription.btn.keep')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["show"])
          ], 64))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})