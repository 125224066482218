import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "type", "autocomplete", "placeholder", "value", "disabled"]

import FormElement from '@/components/Form/FormElement.vue';
import useUniqueId from '@/composables/uid';
import {
  useSlots,
  ref,
  computed,
} from 'vue';

interface Props {
  modelValue: string;
  labelText?: string;
  placeholderText?: string;
  disabled?: boolean;
  inputType?: string;
  autocomplete?: boolean;
  hint?: string;
  loading?: boolean;
  error?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TextField',
  props: {
    modelValue: { default: '' },
    labelText: {},
    placeholderText: {},
    disabled: { type: Boolean, default: false },
    inputType: { default: 'text' },
    autocomplete: { type: Boolean, default: false },
    hint: {},
    loading: { type: Boolean, default: false },
    error: {}
  },
  setup(__props: any, { expose: __expose }) {

const uid = useUniqueId();
const inputElem = ref<HTMLInputElement>();
const slots = useSlots();


const hasIcon = computed(() => !!slots.icon);

const focus = () => inputElem.value?.focus();
__expose({ focus });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FormElement, {
    id: _unref(uid),
    "label-text": _ctx.labelText,
    error: _ctx.error,
    hint: _ctx.hint,
    disabled: _ctx.disabled
  }, _createSlots({
    control: _withCtx(() => [
      _createElementVNode("input", {
        id: _unref(uid),
        ref_key: "inputElem",
        ref: inputElem,
        type: _ctx.inputType,
        autocomplete: _ctx.autocomplete ? inputElem.value : 'null',
        placeholder: _ctx.placeholderText,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        class: _normalizeClass(["block w-full px-3 py-2 h-10 sm:text-sm", _ctx.labelText ? 'mt-1' : '']),
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', $event.target.value)))
      }, null, 42, _hoisted_1)
    ]),
    _: 2
  }, [
    (hasIcon.value)
      ? {
          name: "icon",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icon")
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["id", "label-text", "error", "hint", "disabled"]))
}
}

})