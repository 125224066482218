import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "min-h-[100svh] flex flex-1 flex-col justify-between py-6 px-6 lg:px-8" }
const _hoisted_2 = { class: "flex flex-1 items-center justify-center md:pb-8" }
const _hoisted_3 = { class: "w-full sm:mx-auto sm:max-w-md" }
const _hoisted_4 = { class: "text-center text-2xl font-semibold tracking-tight text-gray-800" }
const _hoisted_5 = {
  key: 0,
  class: "mt-2 text-center text-sm text-gray-500"
}

import { computed, useSlots } from 'vue';
import Logo from '@/components/Core/Logo/Logo.vue';
import NotificationCenter from '@/components/Notification/NotificationCenter.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthPage',
  setup(__props) {

const slots = useSlots();
const hasSubtitle = computed(() => !!slots.subtitle);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(Logo, {
        theme: "dark",
        class: "h-8 md:h-9 relative mb-16 md:mb-0 md:absolute mx-auto md:top-6 md:left-6"
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, [
            _renderSlot(_ctx.$slots, "title")
          ]),
          (hasSubtitle.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                _renderSlot(_ctx.$slots, "subtitle")
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "form")
          ])
        ])
      ])
    ]),
    _createVNode(NotificationCenter)
  ], 64))
}
}

})