import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-10 divide-y divide-gray-200 grid grid-cols-1 lg:grid-cols-6" }
const _hoisted_2 = { class: "col-span-6 lg:col-span-5 xl:col-span-4" }
const _hoisted_3 = { class: "pt-8 col-span-6 lg:col-span-5 xl:col-span-4" }
const _hoisted_4 = {
  key: 0,
  class: "pt-8 col-span-6 lg:col-span-5 xl:col-span-4"
}
const _hoisted_5 = {
  key: 1,
  class: "pt-8 col-span-6 lg:col-span-5 xl:col-span-4"
}

import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { useAuthStore } from '@/stores/auth';
import {
  ref,
  onMounted,
  computed,
} from 'vue';
import { isLeft } from '@/lib/either';
import { AppError, ErrorCode } from '@/lib/error';
import { User } from '@/models';
import PageContent from '@/components/Layout/PageContent.vue';
import UserProfileForm from './UserProfileForm.vue';
import UserAccessRights from './UserAccessRights.vue';
import UserPasswordForm from './UserPasswordForm.vue';
import UserDeleteForm from './UserDeleteForm.vue';

interface Props {
  userId?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserSettingsPage',
  props: {
    userId: {}
  },
  setup(__props: any) {

const router = useRouter();
const authStore = useAuthStore();
const userStore = useUserStore();

const props = __props;
const loading = ref(true);
const errorCode = ref<ErrorCode>();

const userId = computed(() => props.userId || authStore.userId);
const user = computed(() => userStore.getUser(userId.value) as User);
const isCurrentUser = computed(() => userId.value === authStore.userId);
const currentUserAdmin = computed(() => authStore.isAdmin);

onMounted(async () => {
  const cached = userStore.getUser(userId.value);
  errorCode.value = undefined;

  if (!isCurrentUser.value && !currentUserAdmin.value) {
    loading.value = false;
    errorCode.value = ErrorCode.Permission;
    return;
  }

  if (cached) {
    loading.value = false;
  }

  const result = await userStore.loadUserById(userId.value);
  const err = isLeft(result) ? result.left : null;
  const code = AppError.getCode(err);

  switch (code) {
    case null:
      break;
    case ErrorCode.NotFound:
      await router.replace('/team');
      break;
    default:
      errorCode.value = code as ErrorCode;
      break;
  }

  loading.value = false;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageContent, {
    loading: loading.value,
    "error-code": errorCode.value
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(UserProfileForm, { user: user.value }, null, 8, ["user"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(UserAccessRights, { user: user.value }, null, 8, ["user"])
        ]),
        (isCurrentUser.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(UserPasswordForm)
            ]))
          : _createCommentVNode("", true),
        (!isCurrentUser.value && currentUserAdmin.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(UserDeleteForm, { user: user.value }, null, 8, ["user"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["loading", "error-code"]))
}
}

})