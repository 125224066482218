import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "font-medium leading-none text-white"
}

import useComponentCounter from '@/composables/counter';
import { UserIcon } from '@heroicons/vue/24/solid';
import {
  ref,
  computed, FunctionalComponent,
} from 'vue';

interface Props {
  size: 'xs' | 'sm' | 'md' | 'lg';
  imgSrc?: string;
  userName?: string;
  seed?: string;
  icon?: FunctionalComponent;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Avatar',
  props: {
    size: { default: 'md' },
    imgSrc: {},
    userName: {},
    seed: {},
    icon: {}
  },
  setup(__props: any) {

function stringToSeed(str: string): number {
  const charCodes = Array.from(str).map((char) => char.charCodeAt(0));
  return charCodes.reduce((total, code) => total + code, 0);
}

const props = __props;
const imgError = ref(false);
const colors = [
  '#f87171',
  '#fb923c',
  '#fbbf24',
  '#fcd34d',
  '#53cb91',
  '#34d399',
  '#2dd4bf',
  '#22d3ee',
  '#38bdf8',
  '#60a5fa',
  '#818cf8',
];

const randomColor = (seed: number, colorList: string[]): string => {
  const index = Math.abs(seed) % colorList.length;
  return colors[index];
};

const nextColor = (colorList: string[]): string => {
  const counter = useComponentCounter('Avatar');
  let index = 2 * (counter - 1);

  if (index >= colorList.length) {
    index %= colorList.length;
  }

  return colorList[index];
};

const userInitials = computed(() => props.userName?.charAt(0).toUpperCase());

const backgroundColor = computed<string>(() => {
  if (props.seed) {
    return randomColor(stringToSeed(props.seed), colors);
  }

  if (props.userName) {
    return randomColor(stringToSeed(props.userName), colors);
  }

  return nextColor(colors);
});

const containerClassList = computed(() => {
  switch (props.size) {
    case 'xs':
      return 'h-7 w-7 text-sm';
    case 'sm':
      return 'h-8 w-8 text-sm';
    case 'lg':
      return 'h-10 w-10 sm:h-12 sm:w-12 text-lg';
    case 'md':
    default:
      return 'h-9 w-9 sm:h-10 sm:w-10 text-md';
  }
});

const iconClassList = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'h-7 w-7';
    case 'lg':
      return 'h-9 w-9 sm:h-10 sm:w-10';
    case 'md':
    default:
      return 'h-8 w-8 sm:h-9 sm:w-9';
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["inline-flex items-center justify-center rounded-full relative overflow-hidden shadow-inner", containerClassList.value]),
    style: _normalizeStyle({ 'background-color': backgroundColor.value })
  }, [
    (_ctx.imgSrc && !imgError.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.imgSrc,
          onError: _cache[0] || (_cache[0] = ($event: any) => (imgError.value = true)),
          alt: "photo",
          class: "h-full w-full object-cover rounded-full"
        }, null, 40, _hoisted_1))
      : (_ctx.userName)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(userInitials.value), 1))
        : (props.icon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(props.icon), {
              key: 2,
              class: "text-white absolute opacity-90 w-3/4"
            }))
          : (_openBlock(), _createBlock(_unref(UserIcon), {
              key: 3,
              class: _normalizeClass(["text-white absolute -bottom-1 opacity-90", iconClassList.value])
            }, null, 8, ["class"]))
  ], 6))
}
}

})