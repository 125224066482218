import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center min-h-screen py-6 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "flex w-full h-full items-center" }

import { onMounted } from 'vue';
import { useAuthStore } from '@/stores/auth';
import Loader from '@/components/Core/Loader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LogoutPage',
  setup(__props) {

const authStore = useAuthStore();

onMounted(async () => {
  await authStore.signOut();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Loader, { class: "mx-auto" })
    ])
  ]))
}
}

})