import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserStatus } from '@/models/user.model';

interface Props {
  status: UserStatus;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserStatusLabel',
  props: {
    status: { default: UserStatus.Active }
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const statusLabel = computed(() => {
  switch (props.status) {
    case UserStatus.Invited:
      return t('user.status.invited');
    case UserStatus.Active:
    default:
      return t('user.status.active');
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["inline-flex rounded-full px-2 text-xs font-medium leading-5", {
      'bg-green-50 text-green-900': _ctx.status === 'active',
      'bg-gray-100 text-gray-800': _ctx.status === 'invited',
    }])
  }, _toDisplayString(statusLabel.value), 3))
}
}

})