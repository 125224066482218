import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "text-lg xs:text-xl font-bold -mx-4 sm:-mx-6 -mt-5 sm:-mt-6 py-5 xs:py-6 sm:py-7 px-4 sm:px-6 bg-gray-100 rounded-t-xl sm:rounded-t-lg" }
const _hoisted_2 = { class: "flex w-full flex-col sm:flex-row mt-5 sm:mt-7 justify-between" }
const _hoisted_3 = { class: "text-xs text-gray-600" }
const _hoisted_4 = { class: "text-lg xs:text-xl font-semibold mt-2" }
const _hoisted_5 = { class: "text-base font-normal ml-1 text-gray-600" }
const _hoisted_6 = { class: "text-left sm:text-center mt-7 xs:mt-10 sm:mt-0" }
const _hoisted_7 = { class: "text-xs text-gray-600" }
const _hoisted_8 = { class: "flex items-center sm:flex-col mt-2" }
const _hoisted_9 = { class: "mt-0 ml-4 sm:mt-3 sm:ml-0 text-gray-600 text-xs" }
const _hoisted_10 = { class: "rounded-md p-3 sm:p-4 bg-gray-100 mt-5 sm:mt-7 text-xs text-gray-600" }
const _hoisted_11 = { class: "w-full sm:w-auto absolute sm:relative bottom-0 sm:bottom-a -mx-4 sm:-mx-6 sm:-mb-6 mt-12 sm:mt-10 py-4 xs:py-5 sm:py-7 px-4 sm:px-6" }
const _hoisted_12 = { class: "flex flex-col relative sm:flex-row sm:space-x-7 sm:space-y-0 items-center pb-1" }
const _hoisted_13 = {
  key: 0,
  class: "absolute -top-7 sm:relative sm:top-auto text-xs text-red-500"
}
const _hoisted_14 = {
  key: 1,
  class: "hidden sm:block text-xs text-gray-600"
}

import {
  ref,
  computed,
  onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useBillingStore, SubscriptionCalculation } from '@/stores/billing';
import { useNotificationStore } from '@/stores/notification';
import { useAccountStore } from '@/stores/account';
import { useAnalytics } from '@/composables/analytics';
import {
  Plan,
  Subscription,
  NotificationType,
} from '@/models';
import { Money } from '@/lib/money';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { isLeft } from '@/lib/either';
import { PageRouteName } from '@/router/route.names';
import ModalDialog from '@/components/Core/ModalDialog.vue';
import Button from '@/components/Core/Button.vue';
import SeatsCounter from './SeatsCounter.vue';
import PlanFeatureList from './PlanFeatureList.vue';
import FakePaymentDetails from './FakePaymentDetails.vue';
import PaymentDetails from './PaymentDetails.vue';

type ModalElem = InstanceType<typeof ModalDialog>;


export default /*@__PURE__*/_defineComponent({
  __name: 'PlanCalculator',
  setup(__props, { expose: __expose }) {

const { t, d } = useI18n();
const router = useRouter();
const analytics = useAnalytics();
const billingStore = useBillingStore();
const accountStore = useAccountStore();
const notificationStore = useNotificationStore();

const modal = ref<ModalElem>();

const loading = ref(false);
const processing = ref(false);
const error = ref(false);

const calculation = ref<SubscriptionCalculation>();
const account = computed(() => accountStore.account);
const currentSub = computed(() => billingStore.subscription as Subscription);
const isPro = computed(() => currentSub.value.isPro());

const proPlan = computed(() => billingStore.planById(Plan.defaultPro()) as Plan);
const proPrice = computed(() => {
  const p = new Money(proPlan.value.price, proPlan.value.currency);
  return p.toString();
});

const seatsCount = ref(0);
const defaultSeatsCount = computed(() => {
  if (currentSub.value.isPro()) {
    return currentSub.value.seats;
  }

  return currentSub.value.seatsUsed;
});

const monthlyPayment = computed(() => {
  const data = calculation.value as SubscriptionCalculation;
  const seats = seatsCount.value;

  const subtotal = new Money(data.seatAmount, data.currency).multiply(seats);

  return {
    unitAmount: data.seatAmount,
    subtotal: subtotal.amount,
    total: subtotal.amount,
    currency: data.currency,
    trialDays: data.trialDays,
    proration: data.proration,
    createdAt: data.createdAt,
    periodStart: data.periodStart,
    periodEnd: data.periodEnd,
  };
});

const isTrialMode = computed(() => monthlyPayment.value.trialDays > 0);

const subscriptionHint = computed(() => {
  if (currentSub.value.isTrial()) {
    const { total, currency } = monthlyPayment.value;

    return t('billing.price.details.nextPeriod.trial', {
      invoiceDate: d(monthlyPayment.value.periodStart, 'short'),
      totalAmount: new Money(total, currency).toString(),
    });
  }

  if (!monthlyPayment.value.proration) {
    return t('billing.price.details.planHint');
  }

  const seatsDiff = seatsCount.value - currentSub.value.seats;

  switch (true) {
    case seatsDiff > 0:
      return t('billing.price.details.nextPeriod.upgrade', {
        invoiceDate: d(monthlyPayment.value.periodStart, 'short'),
        usersCount: t('billing.price.unit.newUsers', { count: seatsDiff }),
        periodStart: d(monthlyPayment.value.createdAt, 'short'),
        periodEnd: d(monthlyPayment.value.periodStart, 'short'),
      });
    case seatsDiff < 0:
      return t('billing.price.details.nextPeriod.downgrade', {
        invoiceDate: d(monthlyPayment.value.periodStart, 'short'),
        usersCount: t('billing.price.unit.users', { count: Math.abs(seatsDiff) }),
        periodStart: d(monthlyPayment.value.createdAt, 'short'),
        periodEnd: d(monthlyPayment.value.periodStart, 'short'),
      });
    default:
      return t('billing.price.details.nextPeriod.general');
  }
});

const open = () => {
  if (!modal.value) {
    return;
  }

  const trialMode = currentSub.value.isFree() && !account.value?.trialUsed;
  analytics.event('open_paywall', {
    mode: trialMode ? 'start_trial' : 'upgrade_plan',
  });

  modal.value.open();
};

const close = () => {
  if (!modal.value) {
    return;
  }

  const trialMode = currentSub.value.isFree() && !account.value?.trialUsed;
  analytics.event('close_paywall', {
    mode: trialMode ? 'start_trial' : 'upgrade_plan',
  });

  modal.value?.close();
};

__expose({ open, close });

const startCheckout = async () => {
  processing.value = true;

  const successRoute = router.resolve({ name: PageRouteName.Billing });
  const result = await billingStore.startCheckout(
    proPlan.value.id,
    seatsCount.value,
    `${process.env.VUE_APP_BASE_ADDRESS}${successRoute.href}`,
  );

  if (isLeft(result)) {
    error.value = true;
    processing.value = false;
    return;
  }

  const trialMode = currentSub.value.isFree() && !account.value?.trialUsed;
  analytics.event('start_checkout', {
    mode: trialMode ? 'start_trial' : 'upgrade_plan',
    seats: seatsCount.value,
  });

  close();
  processing.value = false;

  setTimeout(() => {
    const checkoutUrl = result.right;
    window.open(checkoutUrl, '_blank')?.focus();
  }, 10);
};

const updateSubscription = async () => {
  const sub = currentSub.value;

  if (sub.sameSeats(seatsCount.value)) {
    close();
    return;
  }

  processing.value = true;

  const result = await billingStore.updateSubscriptionSeats(seatsCount.value);

  if (isLeft(result)) {
    error.value = true;
    processing.value = false;
    return;
  }

  const upgrade = seatsCount.value > sub.seats;
  analytics.event('update_subscription', {
    status: upgrade ? 'upgrade' : 'downgrade',
  });

  close();
  processing.value = false;

  notificationStore.addNotification({
    type: NotificationType.Success,
    title: t('notification.subscriptionUpdated.title'),
    text: t('notification.subscriptionUpdated.text'),
  });
};

const handleBtnClick = async () => {
  const sub = currentSub.value;

  if (sub.isFree()) {
    await startCheckout();
    return;
  }

  await updateSubscription();
};

onMounted(async () => {
  seatsCount.value = defaultSeatsCount.value;
  loading.value = true;

  const result = await billingStore.calculateSubscription(proPlan.value.id);

  if (isLeft(result)) {
    error.value = true;
    return;
  }

  calculation.value = result.right as SubscriptionCalculation;
  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalDialog, {
    ref_key: "modal",
    ref: modal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(isPro.value
        ? _unref(t)('billing.calculator.title.update')
        : _unref(t)('billing.calculator.title.upgrade')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('billing.subscription.plan.title')), 1),
          _createElementVNode("div", _hoisted_4, [
            _createTextVNode(_toDisplayString(_unref(t)('billing.subscription.plan.pro')) + " ", 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(proPrice.value) + " " + _toDisplayString(_unref(t)('billing.price.unit.userMonth')), 1)
          ]),
          _createVNode(PlanFeatureList, { class: "mt-3 grid grid-cols-1" })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)('billing.subscription.seats.title')), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(SeatsCounter, {
              modelValue: seatsCount.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((seatsCount).value = $event)),
              minValue: currentSub.value.seatsUsed,
              maxValue: proPlan.value.maxUsers
            }, null, 8, ["modelValue", "minValue", "maxValue"]),
            _withDirectives(_createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", null, _toDisplayString(_unref(t)('billing.subscription.seats.countUsed', { count: currentSub.value.seatsUsed })), 1)
            ], 512), [
              [_vShow, currentSub.value.seatsUsed === seatsCount.value]
            ])
          ])
        ])
      ]),
      (loading.value)
        ? (_openBlock(), _createBlock(FakePaymentDetails, {
            key: 0,
            class: "mt-8 xs:mt-10",
            title: _unref(t)('billing.price.details.monthlyPayment')
          }, null, 8, ["title"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(PaymentDetails, {
              class: "mt-8 xs:mt-10",
              title: _unref(t)('billing.price.details.monthlyPayment'),
              withDetails: true,
              unitAmount: monthlyPayment.value.unitAmount,
              subtotalAmount: monthlyPayment.value.subtotal,
              subtotalHint: _unref(t)('billing.subscription.seats.count', { count: seatsCount.value }),
              totalAmount: monthlyPayment.value.total,
              totalHint: _unref(t)('billing.price.details.totalHint'),
              trialDays: monthlyPayment.value.trialDays,
              currency: monthlyPayment.value.currency
            }, null, 8, ["title", "unitAmount", "subtotalAmount", "subtotalHint", "totalAmount", "totalHint", "trialDays", "currency"]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("span", null, _toDisplayString(subscriptionHint.value), 1)
            ])
          ], 64)),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(Button, {
            class: "btn-bordered-green w-full sm:w-2/5",
            loading: processing.value,
            disabled: loading.value || processing.value,
            onClick: handleBtnClick
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(CheckCircleIcon), { class: "w-4 h-4 fill-green-500 mr-1.5 shrink-0" }),
              _createTextVNode(" " + _toDisplayString(isPro.value ? _unref(t)('billing.calculator.btn.update') : _unref(t)('common.btn.continue')), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled"]),
          (error.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_unref(t)('common.error.tech')), 1))
            : (!loading.value && isTrialMode.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_unref(t)('billing.calculator.footer.trialHint')), 1))
              : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 512))
}
}

})