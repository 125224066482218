import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-lg font-semibold leading-6 text-gray-900" }
const _hoisted_2 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_3 = { class: "mt-5 grid grid-cols-1 sm:grid-cols-3" }
const _hoisted_4 = { class: "sm:col-span-2" }
const _hoisted_5 = { class: "mt-6 grid grid-cols-1 sm:grid-cols-3" }
const _hoisted_6 = { class: "sm:col-span-2" }
const _hoisted_7 = { class: "mt-8 flex space-x-3" }

import {
  ref,
  watch,
  computed,
} from 'vue';
import validator from 'validator';
import { useI18n } from 'vue-i18n';
import { isLeft } from '@/lib/either';
import { User } from '@/models';
import { useUserStore } from '@/stores/user';
import TextField from '@/components/Form/TextField.vue';
import Button from '@/components/Core/Button.vue';
import StatusMessage from '@/components/Form/StatusMessage.vue';
import { AppError, ErrorCode } from '@/lib/error';

interface Props {
  user: User;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserProfileForm',
  props: {
    user: {}
  },
  setup(__props: any) {

const userStore = useUserStore();
const { t } = useI18n();

const props = __props;

const loading = ref(false);
const status = ref<'success' | 'error'>();
const email = ref(props.user.email);
const name = ref(props.user.name);
const emailError = ref<string>();
const nameError = ref<string>();

const isInvited = computed(() => props.user.isInvited());

watch(() => props.user.email, (newEmail) => {
  email.value = newEmail;
});

watch(() => props.user.name, (newName) => {
  name.value = newName;
});

const validate = (): boolean => {
  email.value = email.value?.trim();
  name.value = name.value?.trim();

  if (!email.value) {
    emailError.value = t('form.input.any.error.required');
  } else if (!validator.isEmail(email.value)) {
    emailError.value = t('form.input.email.error.invalid');
  } else {
    emailError.value = undefined;
  }

  if (!name.value) {
    nameError.value = t('form.input.any.error.required');
  } else {
    nameError.value = undefined;
  }

  return !emailError.value || !nameError.value;
};

const handleSave = async () => {
  const ok = (email.value !== props.user.email || name.value !== props.user.name) && validate();

  if (!ok) {
    return;
  }

  loading.value = true;
  status.value = undefined;

  const result = await userStore.updateUserInfo(props.user.id, {
    email: email.value,
    name: name.value,
  });
  loading.value = false;

  const err = isLeft(result) ? result.left : null;
  switch (AppError.getCode(err)) {
    case null:
      status.value = 'success';
      break;
    case ErrorCode.Invalid: {
      const msg = err?.message || '';

      if (msg.includes('email:')) {
        emailError.value = t('form.input.email.error.invalid');
      }

      if (msg.includes('name:')) {
        nameError.value = t('form.input.any.error.invalid');
      }

      break;
    }
    default:
      status.value = 'error';
      break;
  }
};

const handleCancel = (e: MouseEvent) => {
  const target = e.target as HTMLButtonElement | null;
  email.value = props.user.email;
  name.value = props.user.name;
  target?.blur();
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('profile.personal.title')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('profile.personal.text')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(TextField, {
          "input-type": "text",
          modelValue: name.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((name).value = $event)),
          "label-text": _ctx.$t('form.input.name.label'),
          error: nameError.value,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (nameError.value = undefined))
        }, null, 8, ["modelValue", "label-text", "error"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(TextField, {
          "input-type": "email",
          modelValue: email.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((email).value = $event)),
          "label-text": _ctx.$t('form.input.email.label'),
          error: emailError.value,
          disabled: isInvited.value,
          onInput: _cache[3] || (_cache[3] = ($event: any) => (emailError.value = undefined))
        }, null, 8, ["modelValue", "label-text", "error", "disabled"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(Button, {
        class: "btn-sm",
        disabled: loading.value,
        onClick: handleSave,
        onKeyup: _withKeys(_withModifiers(handleSave, ["prevent"]), ["enter"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.btn.save')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onKeyup"]),
      _createVNode(Button, {
        class: "btn-sm btn-bordered",
        disabled: loading.value,
        onClick: handleCancel,
        onKeyup: _withKeys(_withModifiers(handleCancel, ["prevent"]), ["enter"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('common.btn.cancel')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onKeyup"]),
      _createVNode(StatusMessage, {
        show: !!status.value,
        type: status.value!
      }, null, 8, ["show", "type"])
    ])
  ], 64))
}
}

})