import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "w-full max-w-6xl px-4 py-10 sm:px-6 md:px-8 xl:px-10" }
const _hoisted_2 = {
  key: 0,
  href: "",
  class: "btn btn-bordered"
}
const _hoisted_3 = {
  key: 1,
  href: "#",
  class: "btn btn-bordered"
}
const _hoisted_4 = {
  key: 1,
  class: "flex items-center h-full w-full"
}

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { ErrorCode } from '@/lib/error';
import { PageRouteName } from '@/router/route.names';
import Loader from '@/components/Core/Loader.vue';
import ErrorPage from '@/components/Error/ErrorPage.vue';

interface Props {
  loading?: boolean;
  errorCode?: ErrorCode;
}

interface ErrorDescription {
  title: string;
  text: string;
  allowSupport?: boolean;
  allowRetry?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PageContent',
  props: {
    loading: { type: Boolean },
    errorCode: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const error = computed((): ErrorDescription | null => {
  switch (props.errorCode) {
    case undefined:
      return null;
    case ErrorCode.Permission:
      return {
        title: t('common.error.permission'),
        text: t('common.error.permission.text'),
      };
    default:
      return {
        title: t('common.error.unknown'),
        text: t('common.error.unknown.text'),
        allowSupport: true,
        allowRetry: true,
      };
  }
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (error.value)
      ? (_openBlock(), _createBlock(ErrorPage, {
          key: 0,
          class: "md:-mt-12"
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(error.value.title), 1)
          ]),
          text: _withCtx(() => [
            _createTextVNode(_toDisplayString(error.value.text), 1)
          ]),
          actions: _withCtx(() => [
            (error.value.allowRetry)
              ? (_openBlock(), _createElementBlock("a", _hoisted_2, _toDisplayString(_ctx.$t('common.btn.tryAgain')), 1))
              : (error.value.allowSupport)
                ? (_openBlock(), _createElementBlock("a", _hoisted_3, _toDisplayString(_ctx.$t('common.btn.support')), 1))
                : (_openBlock(), _createBlock(_component_router_link, {
                    key: 2,
                    to: { name: _unref(PageRouteName).Dashboard },
                    class: "btn btn-bordered"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.btn.gotIt')), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]))
          ]),
          _: 1
        }))
      : (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(Loader, { class: "mx-auto text-gray-500" })
          ]))
        : _renderSlot(_ctx.$slots, "default", { key: 2 })
  ]))
}
}

})