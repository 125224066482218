import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-lg font-semibold leading-6 text-gray-900" }
const _hoisted_2 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_3 = { class: "mt-5 flex space-x-3" }

import {
  watch,
  ref,
  computed,
  onBeforeMount,
} from 'vue';
import { isLeft } from '@/lib/either';
import { AppError, ErrorCode } from '@/lib/error';
import { User, UserRole } from '@/models';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import Switch from '@/components/Form/Switch.vue';
import StatusMessage from '@/components/Form/StatusMessage.vue';

interface Props {
  user: User;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserAccessRights',
  props: {
    user: {}
  },
  setup(__props: any) {

const { t } = useI18n();
const userStore = useUserStore();

const props = __props;

const loading = ref(false);
const isAdmin = ref(props.user.isAdmin());
const errorMessage = ref<string>();
const currentUserWasAdmin = ref(false);
const currentUserAdmin = computed(() => userStore.currentUser?.isAdmin());

watch(() => props.user, (user) => {
  isAdmin.value = user.isAdmin();
});

onBeforeMount(() => {
  currentUserWasAdmin.value = userStore.currentUser?.isAdmin();
});

const handleChange = async () => {
  loading.value = true;
  errorMessage.value = undefined;

  const result = await userStore.updateUserRole(
    props.user.id,
    isAdmin.value ? UserRole.Admin : UserRole.User,
  );
  const err = isLeft(result) ? result.left : null;

  switch (AppError.getCode(err)) {
    case null:
      loading.value = false;
      return;
    case ErrorCode.Conflict:
      errorMessage.value = t('profile.accessRights.error.lastAdmin');
      break;
    default:
      errorMessage.value = t('common.error.tech');
      break;
  }

  setTimeout(() => {
    isAdmin.value = props.user.isAdmin();
  }, 100);

  loading.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('profile.accessRights.title')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('profile.accessRights.text')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(Switch, {
        modelValue: isAdmin.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((isAdmin).value = $event)),
          handleChange
        ],
        disabled: loading.value || (!currentUserAdmin.value && !currentUserWasAdmin.value)
      }, null, 8, ["modelValue", "disabled"]),
      _createVNode(StatusMessage, {
        show: !!errorMessage.value,
        type: "error"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(errorMessage.value), 1)
        ]),
        _: 1
      }, 8, ["show"])
    ])
  ], 64))
}
}

})