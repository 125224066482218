import { watch, onMounted } from 'vue';
import { useRoute, RouteLocation } from 'vue-router';
import { useGtag } from '@/composables/gtag';

export const usePageViews = () => {
  const route = useRoute();
  const gtag = useGtag();

  const sendPageView = (to: RouteLocation) => {
    gtag('event', 'page_view', {
      page_path: to.fullPath,
      page_title: document.title,
      page_location: window.location.href,
    });
  };

  onMounted(() => {
    sendPageView(route);
  });

  watch(
    () => route.fullPath,
    () => {
      sendPageView(route);
    },
  );
};
