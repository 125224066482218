import { AppError, ErrorSub } from '@/lib/error';

export enum RpcMethod {
  Ping = 'ping',
  UserSignOut = 'user.signOut',
}

export type RpcMessage<T = unknown> = {
  method: RpcMethod,
  params: T;
}

export function useExtensionCall(
  recipientId: string,
) {
  const call = async (msg: RpcMessage): Promise<AppError | null> => {
    if (!window.chrome) {
      return AppError.newInternalError(ErrorSub.InternalError, 'extension not supported');
    }

    if (!window.chrome.runtime) {
      return AppError.newNotFoundError('extension_not_found');
    }

    try {
      await window.chrome.runtime.sendMessage(recipientId, msg);
      return null;
    } catch (err) {
      console.error('Failed to send message to extension', err);
      return AppError.newNotFoundError('extension_not_found');
    }
  };

  return { call };
}
