import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-40 flex" }
const _hoisted_2 = { class: "absolute top-0 right-0 -mr-12 pt-2" }
const _hoisted_3 = { class: "hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col" }
const _hoisted_4 = { class: "flex min-h-0 flex-1 flex-col bg-black" }

import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageSidebar',
  setup(__props, { expose: __expose }) {

const sidebarOpen = ref(false);

const open = () => {
  sidebarOpen.value = true;
};
const close = () => {
  sidebarOpen.value = false;
};

__expose({ open, close });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(TransitionRoot), {
      as: "template",
      show: sidebarOpen.value
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Dialog), {
          as: "div",
          class: "relative z-40 lg:hidden",
          onClose: _cache[1] || (_cache[1] = ($event: any) => (close()))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(TransitionChild), {
              as: "template",
              enter: "transition-opacity ease-linear duration-300",
              "enter-from": "opacity-0",
              "enter-to": "opacity-100",
              leave: "transition-opacity ease-linear duration-300",
              "leave-from": "opacity-100",
              "leave-to": "opacity-0"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("div", { class: "fixed inset-0 bg-gray-600 bg-opacity-75" }, null, -1)
              ])),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(TransitionChild), {
                as: "template",
                enter: "transition ease-in-out duration-300 transform",
                "enter-from": "-translate-x-full",
                "enter-to": "translate-x-0",
                leave: "transition ease-in-out duration-300 transform",
                "leave-from": "translate-x-0",
                "leave-to": "-translate-x-full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogPanel), { class: "relative flex w-full max-w-xs flex-1 flex-col bg-black pb-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(TransitionChild), {
                        as: "template",
                        enter: "ease-in-out duration-300",
                        "enter-from": "opacity-0",
                        "enter-to": "opacity-100",
                        leave: "ease-in-out duration-300",
                        "leave-from": "opacity-100",
                        "leave-to": "opacity-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (close()))
                            }, [
                              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, "Close sidebar", -1)),
                              _createVNode(_unref(XMarkIcon), {
                                class: "h-6 w-6 text-white",
                                "aria-hidden": "true"
                              })
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _renderSlot(_ctx.$slots, "default")
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              }),
              _cache[4] || (_cache[4] = _createElementVNode("div", {
                class: "w-14 flex-shrink-0",
                "aria-hidden": "true"
              }, null, -1))
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["show"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
}

})