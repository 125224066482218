import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center min-h-screen py-6 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "flex w-full h-full items-center justify-center" }

import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { PageRouteName } from '@/router/route.names';
import { useNotificationStore } from '@/stores/notification';
import { useAuthStore } from '@/stores/auth';
import { NotificationType } from '@/models';
import Loader from '@/components/Core/Loader.vue';

interface Props {
  token: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'VerificationPage',
  props: {
    token: {}
  },
  setup(__props: any) {

const router = useRouter();
const authStore = useAuthStore();
const notificationStore = useNotificationStore();
const props = __props;
const { t } = useI18n();

onMounted(async () => {
  const err = await authStore.verifyEmail(props.token);

  if (err) {
    notificationStore.addNotification({
      type: NotificationType.Error,
      title: t('notification.expiredLink.title'),
      text: t('notification.expiredLink.text'),
    });
  }

  await router.replace({ name: PageRouteName.Dashboard });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Loader)
    ])
  ]))
}
}

})