import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "relative flex justify-start" }
const _hoisted_3 = { class: "bg-white pr-2 text-xs text-gray-600" }




export default /*@__PURE__*/_defineComponent({
  __name: 'FakePaymentDetails',
  props: {
    title: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "absolute inset-0 flex items-center",
        "aria-hidden": "true"
      }, [
        _createElementVNode("div", { class: "w-full border-t border-gray-300" })
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"mt-5 pb-0.5 space-y-4\"><div class=\"flex justify-between items-center\"><div class=\"shine-block bg-gray-300 h-3.5 w-32\"></div><div class=\"shine-block bg-gray-300 h-3.5 w-9\"></div></div><div class=\"flex justify-between items-center\"><div class=\"shine-block bg-gray-300 h-3.5 w-20\"></div><div class=\"shine-block bg-gray-300 h-3.5 w-9\"></div></div><div class=\"flex justify-between items-center\"><div class=\"shine-block bg-gray-300 h-3.5 w-28\"></div><div class=\"shine-block bg-gray-300 h-3.5 w-9\"></div></div></div>", 1))
  ]))
}
}

})