import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "relative rounded-md" }

import { computed } from 'vue';

interface Props {
  id: string;
  labelText?: string;
  error?: string;
  hint?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormElement',
  props: {
    id: {},
    labelText: {},
    error: {},
    hint: {}
  },
  setup(__props: any) {

const props = __props;

const hasError = computed(() => !!props.error);
const hintText = computed(() => props.error || props.hint || '');

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-element relative", { 'form-element-error': hasError.value }])
  }, [
    (_ctx.labelText)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "block mb-1 text-sm font-medium leading-6 text-gray-700"
        }, _toDisplayString(_ctx.labelText), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "control"),
      _renderSlot(_ctx.$slots, "icon")
    ]),
    _createElementVNode("p", {
      class: _normalizeClass(["mt-0.5 max-w-full text-[11px] font-normal absolute", { 'text-red-600': hasError.value, 'text-gray-400': !hasError.value }])
    }, _toDisplayString(hintText.value), 3)
  ], 2))
}
}

})