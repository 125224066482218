import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import { ref, computed, onBeforeMount } from 'vue';
import FakePageLayout from '@/components/Layout/FakePageLayout.vue';
import PageLayout from '@/components/Layout/PageLayout.vue';
import NotificationCenter from '@/components/Notification/NotificationCenter.vue';
import { isLeft } from '@/lib/either';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';
import { useAccountStore } from '@/stores/account';
import { useBillingStore } from '@/stores/billing';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import {
  BoltIcon,
  Squares2X2Icon,
  UserCircleIcon,
  UserGroupIcon,
  WalletIcon,
} from '@heroicons/vue/24/outline';
import { AppError, ErrorCode } from '@/lib/error';
import PageContent from '@/components/Layout/PageContent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PageContainer',
  setup(__props) {

const authStore = useAuthStore();
const userStore = useUserStore();
const accountStore = useAccountStore();
const billingStore = useBillingStore();

const { t } = useI18n();
const route = useRoute();

const loading = ref(true);
const errorCode = ref<ErrorCode>();
const pageTitle = computed(() => {
  const routeName = String(route.name);
  return routeName ? t(`page.${routeName}.title`) : '';
});
const currentUser = computed(() => userStore.currentUser);
const sidebarNavigation = computed(() => {
  const items = [
    {
      name: t('navigation.item.dashboard'),
      href: '/',
      icon: Squares2X2Icon,
    },
    {
      name: t('navigation.item.team'),
      href: '/team',
      icon: UserGroupIcon,
    },
    {
      name: t('navigation.item.settings'),
      href: '/settings',
      icon: UserCircleIcon,
    },
    {
      name: t('navigation.item.downloads'),
      href: '/downloads',
      icon: BoltIcon,
    },
  ];

  if (userStore.currentUser.isAdmin()) {
    items.push({
      name: t('navigation.item.billing'),
      href: '/billing',
      icon: WalletIcon,
    });
  }

  return items;
});

onBeforeMount(async () => {
  loading.value = true;
  const refreshErr = await authStore.refreshToken();

  if (refreshErr) {
    await authStore.signOut();
    return;
  }

  const results = await Promise.all([
    accountStore.getAccountInfo(),
    userStore.loadUserById(authStore.userId),
    billingStore.getSubscription(),
    billingStore.getPlanList(),
  ]);

  if (results.some((r) => isLeft<AppError, unknown>(r))) {
    errorCode.value = ErrorCode.Internal;
  }

  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (loading.value)
    ? (_openBlock(), _createBlock(FakePageLayout, { key: 0 }))
    : (_openBlock(), _createBlock(PageLayout, {
        key: 1,
        "current-user": currentUser.value,
        "page-title": pageTitle.value,
        "sidebar-navigation": sidebarNavigation.value
      }, {
        default: _withCtx(() => [
          (errorCode.value)
            ? (_openBlock(), _createBlock(PageContent, {
                key: 0,
                errorCode: errorCode.value
              }, null, 8, ["errorCode"]))
            : (_openBlock(), _createBlock(_component_RouterView, { key: 1 })),
          _createVNode(NotificationCenter)
        ]),
        _: 1
      }, 8, ["current-user", "page-title", "sidebar-navigation"]))
}
}

})