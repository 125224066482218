import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, withCtx as _withCtx, unref as _unref, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-6 -mb-3 text-center text-sm text-red-500"
}
const _hoisted_2 = { class: "text-sm text-center mt-8" }

import { ref, VueElement } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import isEmail from 'validator/lib/isEmail';
import { AppError, ErrorCode } from '@/lib/error';
import { AuthRouteName } from '@/router/route.names';
import AuthPage from '@/components/Auth/AuthPage.vue';
import TextField from '@/components/Form/TextField.vue';
import Button from '@/components/Core/Button.vue';
import Loader from '@/components/Core/Loader.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordStep1',
  emits: ['success'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n({ useScope: 'global' });
const authStore = useAuthStore();

const loading = ref(false);
const email = ref('');

const emailError = ref<string>();
const formError = ref<string>();

const emailField = ref<VueElement>();

const emit = __emit;

const validateEmail = (): boolean => {
  email.value = email.value.trim();

  if (!email.value) {
    emailError.value = t('form.input.any.error.required');
  } else if (!isEmail(email.value)) {
    emailError.value = t('form.input.email.error.invalid');
  } else {
    emailError.value = undefined;
  }

  return !emailError.value;
};

const handleSubmit = async (): Promise<void> => {
  if (loading.value || !validateEmail()) {
    return;
  }

  loading.value = true;
  formError.value = undefined;
  emailError.value = undefined;

  const err = await authStore.startPasswordReset(email.value);

  switch (AppError.getCode(err)) {
    case null:
      emit('success');
      break;
    case ErrorCode.Unauthorized:
    case ErrorCode.Invalid:
      formError.value = t('auth.resetPassword.step1.error.email');
      loading.value = false;
      break;
    default:
      formError.value = t('common.error.tech');
      break;
  }

  loading.value = false;
};

const handleEmailInput = (): void => {
  emailError.value = undefined;
};

const handleEmailChange = (byEnter: boolean): void => {
  const ok = validateEmail();

  if (byEnter && ok) {
    handleSubmit();
  }
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(AuthPage, null, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.step1.title')), 1)
    ]),
    subtitle: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.step1.subtitle')), 1)
    ]),
    form: _withCtx(() => [
      _createVNode(TextField, {
        modelValue: email.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
        ref_key: "emailField",
        ref: emailField,
        "input-type": "email",
        "label-text": _ctx.$t('form.input.email.label'),
        class: "mt-8",
        error: emailError.value,
        onInput: handleEmailInput,
        onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (handleEmailChange(true)), ["prevent"]), ["enter"])),
        onChange: _cache[2] || (_cache[2] = ($event: any) => (handleEmailChange(false)))
      }, null, 8, ["modelValue", "label-text", "error"]),
      (formError.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(formError.value), 1))
        : _createCommentVNode("", true),
      _createVNode(Button, {
        class: "w-full mt-10",
        onClick: handleSubmit,
        onKeyup: _withKeys(_withModifiers(handleSubmit, ["prevent"]), ["enter"])
      }, {
        default: _withCtx(() => [
          (loading.value)
            ? (_openBlock(), _createBlock(Loader, {
                key: 0,
                class: "text-white"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('common.btn.continue')), 1)
              ], 64))
        ]),
        _: 1
      }, 8, ["onKeyup"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: { name: _unref(AuthRouteName).Login },
          class: "text-gray-600 hover:underline font-medium"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.returnToSignIn')), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }))
}
}

})