import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-10 px-4 py-6 sm:px-6 sm:py-7 text-center rounded-xl border border-gray-300 bg-white shadow-sm" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "mt-4 text-xl" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "text-sm text-gray-500 mt-2" }
const _hoisted_6 = { class: "text-sm text-center mt-3 sm:mt-5" }

import { useRouter } from 'vue-router';
import { PageRouteName } from '@/router/route.names';
import { useAnalytics } from '@/composables/analytics';
import AuthPage from '@/components/Auth/AuthPage.vue';
import Button from '@/components/Core/Button.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'OnboardingPage',
  setup(__props) {

const router = useRouter();
const analytics = useAnalytics();

const extensionUrl = process.env.VUE_APP_EXTENSION_URL;

const trackClick = (eventName: string) => {
  analytics.event(eventName, { source: 'onboarding' });
};

const handleAddClick = async () => {
  trackClick('add_extension_click');
  await router.push({ name: PageRouteName.Dashboard });
  window.open(extensionUrl, '_blank')?.focus();
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createBlock(AuthPage, null, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('onboarding.installExtension.title')), 1)
    ]),
    subtitle: _withCtx(() => [
      _createVNode(_component_i18n_t, {
        keypath: "onboarding.installExtension.text",
        tag: "span"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: { name: _unref(PageRouteName).Dashboard },
            class: "link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('onboarding.installExtension.text.link')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      })
    ]),
    form: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("a", {
          href: _unref(extensionUrl),
          target: "_blank",
          onClick: _withModifiers(handleAddClick, ["prevent"])
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            class: "w-12 h-12 mx-auto sm:w-14 sm:h-14",
            src: "/icons/chrome-logo.svg",
            alt: "Chrome"
          }, null, -1)
        ]), 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("a", {
            href: _unref(extensionUrl),
            target: "_blank",
            class: "font-medium block text-gray-900 hover:text-gray-600",
            onClick: _withModifiers(handleAddClick, ["prevent"])
          }, _toDisplayString(_ctx.$t('onboarding.installExtension.chrome.title')), 9, _hoisted_4),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('onboarding.installExtension.chrome.text')), 1),
          _createVNode(Button, {
            class: "w-full mx-auto mt-6 sm:mt-8",
            onClick: handleAddClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('onboarding.installExtension.chrome.btn')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_router_link, {
              to: { name: _unref(PageRouteName).Dashboard },
              class: "text-gray-600 hover:underline font-medium",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (trackClick('skip_extension_click')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('onboarding.installExtension.chrome.skipBtn')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})