import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, unref as _unref, Fragment as _Fragment, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-6 -mb-3 text-center text-sm text-red-500"
}
const _hoisted_2 = { class: "text-sm text-center mt-8" }
const _hoisted_3 = {
  href: "/password/reset",
  class: "btn btn-bordered"
}

import {
  ref,
  VueElement,
  onBeforeMount,
} from 'vue';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { Container } from 'typedi';
import { AppError, ErrorCode } from '@/lib/error';
import { AuthRouteName } from '@/router/route.names';
import validator from 'validator';
import AuthPage from '@/components/Auth/AuthPage.vue';
import PasswordField from '@/components/Form/PasswordField.vue';
import Button from '@/components/Core/Button.vue';
import ErrorPage from '@/components/Error/ErrorPage.vue';
import Loader from '@/components/Core/Loader.vue';

interface Props {
  resetToken: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordStep3',
  props: {
    resetToken: {}
  },
  setup(__props: any) {

const { t } = useI18n({ useScope: 'global' });
const { passwordRules } = Container.get('config.app');
const router = useRouter();
const authStore = useAuthStore();

const loading = ref(false);
const success = ref(false);
const expiredToken = ref(false);
const password = ref('');
const confirm = ref('');

const passwordError = ref<string>();
const confirmError = ref<string>();
const formError = ref<string>();

const confirmField = ref<VueElement>();

const props = __props;

onBeforeMount(async () => {
  loading.value = true;
  const err = await authStore.verifyResetPasswordToken(props.resetToken);
  expiredToken.value = !!err;
  loading.value = false;
});

const validatePassword = (): boolean => {
  password.value = password.value.trim();

  if (!password.value) {
    passwordError.value = t('form.input.any.error.required');
  } else if (!validator.isStrongPassword(password.value, {
    ...passwordRules,
    returnScore: false,
  })) {
    passwordError.value = t('form.input.password.error.weak');
  } else {
    passwordError.value = undefined;
  }

  return !passwordError.value;
};

const validateConfirm = (): boolean => {
  confirm.value = confirm.value.trim();

  if (!confirm.value) {
    confirmError.value = t('form.input.any.error.required');
  } else if (confirm.value !== password.value) {
    confirmError.value = t('form.input.confirmPassword.error.mismatch');
  } else {
    confirmError.value = undefined;
  }

  return !confirmError.value;
};

const handleSubmit = async (): Promise<void> => {
  if (loading.value) {
    return;
  }

  validatePassword();
  validateConfirm();

  if (passwordError.value || confirmError.value) {
    return;
  }

  loading.value = true;
  formError.value = undefined;
  passwordError.value = undefined;
  confirmError.value = undefined;

  const err = await authStore.resetPassword(props.resetToken, password.value);

  switch (AppError.getCode(err)) {
    case null:
      success.value = true;
      break;
    case ErrorCode.Unauthorized:
      expiredToken.value = true;
      break;
    case ErrorCode.Invalid:
      passwordError.value = t('form.input.any.error.required');
      break;
    default:
      formError.value = t('common.error.tech');
      break;
  }

  loading.value = false;
};

const handleSignIn = async (): Promise<void> => {
  if (loading.value) {
    return;
  }

  if (authStore.lastEmail && password.value) {
    loading.value = true;
    const err = await authStore.signIn(authStore.lastEmail, password.value);
    loading.value = false;

    if (!err) {
      return;
    }
  }

  await router.replace({ name: AuthRouteName.Login });
};

const handlePasswordInput = (): void => {
  passwordError.value = undefined;
};

const handlePasswordChange = (byEnter: boolean): void => {
  const ok = validatePassword();

  if (byEnter && ok) {
    confirmField.value?.focus();
  }
};

const handleConfirmInput = (): void => {
  confirmError.value = undefined;
};

const handleConfirmChange = (byEnter: boolean): void => {
  const ok = validateConfirm();

  if (byEnter && ok) {
    handleSubmit();
  }
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (!expiredToken.value)
    ? (_openBlock(), _createBlock(AuthPage, { key: 0 }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.step3.title')), 1)
        ]),
        form: _withCtx(() => [
          _createVNode(PasswordField, {
            modelValue: password.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((password).value = $event)),
            ref: "passwordField",
            class: "mt-10",
            "label-text": _ctx.$t('form.input.newPassword.label'),
            error: passwordError.value,
            onInput: handlePasswordInput,
            onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (handlePasswordChange(true)), ["prevent"]), ["enter"])),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (handlePasswordChange(false)))
          }, null, 8, ["modelValue", "label-text", "error"]),
          _createVNode(PasswordField, {
            modelValue: confirm.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((confirm).value = $event)),
            ref_key: "confirmField",
            ref: confirmField,
            class: "mt-6",
            "label-text": _ctx.$t('form.input.confirmPassword.label'),
            error: confirmError.value,
            onInput: handleConfirmInput,
            onKeyup: _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (handleConfirmChange(true)), ["prevent"]), ["enter"])),
            onChange: _cache[5] || (_cache[5] = ($event: any) => (handleConfirmChange(false)))
          }, null, 8, ["modelValue", "label-text", "error"]),
          (formError.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(formError.value), 1))
            : _createCommentVNode("", true),
          _createVNode(Button, {
            class: "w-full mt-10",
            disabled: loading.value || success.value,
            onClick: handleSubmit,
            onKeyup: _withKeys(_withModifiers(handleSubmit, ["prevent"]), ["enter"])
          }, {
            default: _withCtx(() => [
              (loading.value)
                ? (_openBlock(), _createBlock(Loader, {
                    key: 0,
                    class: "text-gray-600"
                  }))
                : (success.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(_unref(CheckCircleIcon), {
                        class: "-ml-1 mr-1 h-4 w-4 text-green-600",
                        "aria-hidden": "true"
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('auth.resetPassword.passwordChanged')), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.btn.continue')), 1)
                    ], 64))
            ]),
            _: 1
          }, 8, ["disabled", "onKeyup"]),
          _createElementVNode("div", _hoisted_2, [
            (success.value)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  class: "text-gray-600 hover:underline font-medium",
                  onClick: _withModifiers(handleSignIn, ["prevent"])
                }, _toDisplayString(_ctx.$t('auth.resetPassword.signIn')), 1))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: { name: _unref(AuthRouteName).Login },
                  class: "text-gray-600 hover:underline font-medium"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.returnToSignIn')), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(ErrorPage, { key: 1 }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.error.invalidToken')), 1)
        ]),
        text: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.error.invalidToken.text')), 1)
        ]),
        actions: _withCtx(() => [
          _createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t('auth.resetPassword.error.invalidToken.btnReset')), 1),
          _createVNode(_component_router_link, {
            to: { name: _unref(AuthRouteName).Login },
            class: "btn btn-bordered"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('auth.resetPassword.returnToSignIn')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      }))
}
}

})