import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-10 divide-y divide-gray-200 grid grid-cols-1 lg:grid-cols-6" }
const _hoisted_2 = { class: "md:col-span-5 lg:col-span-4" }
const _hoisted_3 = { class: "text-xl font-semibold flex items-center" }
const _hoisted_4 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_5 = { class: "md:col-span-5 lg:col-span-4 pt-8" }
const _hoisted_6 = { class: "text-lg font-semibold leading-6 text-gray-900" }
const _hoisted_7 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_8 = { class: "mt-5 block space-y-2 sm:flex sm:items-center sm:space-x-4 sm:space-y-0" }
const _hoisted_9 = { class: "md:col-span-5 lg:col-span-4 pt-8" }
const _hoisted_10 = { class: "text-lg font-semibold flex items-center" }
const _hoisted_11 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_12 = { class: "mt-5 block space-y-2 sm:flex sm:items-center sm:space-x-4 sm:space-y-0" }

import {
  computed,
  onMounted,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { AppError, ErrorCode } from '@/lib/error';
import { isLeft, isRight } from '@/lib/either';
import { useRouter } from 'vue-router';
import { PageRouteName } from '@/router/route.names';
import { useUserStore } from '@/stores/user';
import { useBillingStore } from '@/stores/billing';
import { useNotificationStore } from '@/stores/notification';
import { useAnalytics } from '@/composables/analytics';
import { NotificationData, NotificationType } from '@/models';
import Button from '@/components/Core/Button.vue';
import PageContent from '@/components/Layout/PageContent.vue';
import SubscriptionCard from './Subscription/SubscriptionCard.vue';

const ERR_TIMEOUT = 3000;

interface Props {
  state?: 'success' | 'fail' | 'upgrade'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BillingPage',
  props: {
    state: {}
  },
  setup(__props: any) {

const { t } = useI18n();
const router = useRouter();
const userStore = useUserStore();
const billingStore = useBillingStore();
const notificationStore = useNotificationStore();
const analytics = useAnalytics();

const props = __props;
const loading = ref(false);
const errorCode = ref<ErrorCode>();

const isAdmin = computed(() => userStore.currentUser.isAdmin());

const billingLoading = ref(false);
const historyLoading = ref(false);
const billingError = ref<string | null>(null);
const historyError = ref<string | null>(null);

const openCustomerPortal = async (): Promise<AppError | void> => {
  const redirectUrl = window.location.href;
  const result = await billingStore.createPortalSession(redirectUrl);

  if (isRight(result)) {
    const url = result.right;
    setTimeout(() => {
      window.open(url, '_blank')?.focus();
    }, 10);

    return undefined;
  }

  return result.left;
};

const handleBillingBtnClick = async () => {
  billingLoading.value = true;
  const err = await openCustomerPortal();

  switch (AppError.getCode(err)) {
    case null:
      break;
    case ErrorCode.NotFound:
      billingError.value = t('page.billing.error.infoNotFound');
      break;
    case ErrorCode.Permission:
      billingError.value = t('page.billing.error.permissionsDenied');
      break;
    default:
      billingError.value = t('common.error.tech');
  }

  setTimeout(() => { billingError.value = null; }, ERR_TIMEOUT);
  billingLoading.value = false;
};

const handleHistoryBtnClick = async () => {
  historyLoading.value = true;
  const err = await openCustomerPortal();

  switch (AppError.getCode(err)) {
    case null:
      break;
    case ErrorCode.NotFound:
      historyError.value = t('page.billing.error.historyNotFound');
      break;
    case ErrorCode.Permission:
      historyError.value = t('page.billing.error.permissionsDenied');
      break;
    default:
      historyError.value = t('common.error.unknown');
  }

  setTimeout(() => { historyError.value = null; }, ERR_TIMEOUT);
  historyLoading.value = false;
};

onMounted(async () => {
  if (!isAdmin.value) {
    errorCode.value = ErrorCode.Permission;
    return;
  }

  let notification: NotificationData | undefined;

  switch (props.state) {
    case 'success':
      analytics.event('complete_checkout');
      notification = {
        type: NotificationType.Success,
        title: t('notification.subscriptionSuccess.title'),
        text: t('notification.subscriptionSuccess.text'),
      };
      break;
    case 'fail':
      analytics.event('fail_checkout');
      notification = {
        type: NotificationType.Error,
        title: t('notification.subscriptionFail.title'),
        text: t('notification.subscriptionFail.text'),
      };
      break;
    default:
      break;
  }

  if (notification) {
    notificationStore.addNotification(notification);
    await router.replace({ name: PageRouteName.Billing });
    return;
  }

  loading.value = true;
  const result = await billingStore.getSubscription();

  if (isLeft(result)) {
    const err = result.left;
    errorCode.value = AppError.getCode(err) as ErrorCode;
    loading.value = false;
  }

  loading.value = false;
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageContent, {
    loading: loading.value,
    errorCode: errorCode.value
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_unref(t)('page.billing.subscriptionForm.title')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)('page.billing.subscriptionForm.text')), 1),
          _createVNode(SubscriptionCard, {
            showCalculator: props.state === 'upgrade',
            class: "mt-5 w-full md:w-4/5 lg:w-full xl:w-4/5"
          }, null, 8, ["showCalculator"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_6, _toDisplayString(_unref(t)('page.billing.billingInfo.title')), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(t)('page.billing.billingInfo.text')), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(Button, {
              class: "btn-sm btn-bordered basis-44",
              loading: billingLoading.value,
              onClick: handleBillingBtnClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('page.billing.billingInfo.btn')), 1)
              ]),
              _: 1
            }, 8, ["loading"]),
            _createElementVNode("div", {
              class: _normalizeClass([
              'text-xs text-red-600 transition duration-300 ease-in-out absolute sm:relative',
              !!billingError.value ? 'opacity-100' : 'opacity-0',
            ])
            }, _toDisplayString(billingError.value), 3)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_10, _toDisplayString(_unref(t)('page.billing.history.title')), 1),
            _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(t)('page.billing.history.text')), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(Button, {
              class: "btn-sm btn-bordered basis-44",
              loading: historyLoading.value,
              onClick: handleHistoryBtnClick
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('common.btn.viewMore')), 1)
              ]),
              _: 1
            }, 8, ["loading"]),
            _createElementVNode("div", {
              class: _normalizeClass([
              'text-xs text-red-600 transition duration-300 ease-in-out absolute sm:relative',
              !!historyError.value ? 'opacity-100' : 'opacity-0',
            ])
            }, _toDisplayString(historyError.value), 3)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading", "errorCode"]))
}
}

})