import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_2 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_3 = { class: "mt-3 relative flex items-center gap-3 md:gap-5" }
const _hoisted_4 = {
  key: 0,
  class: "absolute -bottom-5 left-0 text-[11px] text-green-600 flex items-center"
}
const _hoisted_5 = { class: "hidden sm:inline-block" }
const _hoisted_6 = { class: "sm:hidden" }
const _hoisted_7 = {
  key: 0,
  class: "mt-1.5 text-xs w-full text-yellow-600"
}

import { CheckIcon } from '@heroicons/vue/24/outline';
import Button from '@/components/Core/Button.vue';
import TextField from '@/components/Form/TextField.vue';
import { PageRouteName } from '@/router/route.names';
import {
  ref,
} from 'vue';

interface Props {
  userLimit?: boolean;
  loading?: boolean;
  error?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'InviteForm',
  props: {
    userLimit: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    error: {}
  },
  emits: ['input', 'submit'],
  setup(__props: any, { expose: __expose, emit: __emit }) {



const emit = __emit;
const email = ref('');
const showSuccessMessage = ref(false);

const success = () => {
  email.value = '';

  if (!showSuccessMessage.value) {
    showSuccessMessage.value = true;
    setTimeout(() => {
      showSuccessMessage.value = false;
    }, 3000);
  }
};

__expose({ success });

const handleInput = () => {
  emit('input', email.value);
};

const handleSubmit = () => {
  if (email.value.length === 0) {
    return;
  }

  emit('submit', email.value);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('page.team.inviteForm.title')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('page.team.inviteForm.text')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(TextField, {
        modelValue: email.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
        "input-type": "email",
        class: "w-3/4 sm:w-4/5",
        disabled: _ctx.userLimit,
        "placeholder-text": _ctx.$t('form.input.email.textPlaceholder'),
        error: _ctx.error,
        onInput: handleInput,
        onKeyup: _withKeys(_withModifiers(handleSubmit, ["prevent"]), ["enter"])
      }, null, 8, ["modelValue", "disabled", "placeholder-text", "error", "onKeyup"]),
      (showSuccessMessage.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_unref(CheckIcon), { class: "h-3 w-3 stroke-2 text-green-600 mr-1" }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('page.team.inviteForm.success')), 1)
          ]))
        : _createCommentVNode("", true),
      _createVNode(Button, {
        class: "w-1/4 sm:w-1/5 sm:min-w-[110px] disabled:bg-gray-800",
        disabled: _ctx.userLimit,
        loading: _ctx.loading,
        onClick: handleSubmit,
        onKeyup: _withKeys(_withModifiers(handleSubmit, ["prevent"]), ["enter"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('page.team.inviteForm.btn')), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('common.btn.invite')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading", "onKeyup"])
    ]),
    (_ctx.userLimit && !showSuccessMessage.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_i18n_t, {
            keypath: "page.team.inviteForm.upgrade.text",
            tag: "div"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: _unref(PageRouteName).Billing },
                class: "underline"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('page.team.inviteForm.upgrade.link')), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})