import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "flex flex-1 flex-col overflow-y-auto" }
const _hoisted_2 = { class: "flex-1 space-y-5 px-4 mt-6 animate-pulse" }
const _hoisted_3 = { class: "flex flex-col min-h-screen lg:pl-64" }
const _hoisted_4 = { class: "sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow" }
const _hoisted_5 = {
  type: "button",
  class: "border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-0 focus:text-gray-500 lg:hidden"
}

import { Bars3BottomLeftIcon } from '@heroicons/vue/24/outline';
import PageSidebar from '@/components/Layout/PageSidebar.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'FakePageLayout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(PageSidebar, {
      ref: "sidebar",
      class: "hidden lg:block"
    }, {
      default: _withCtx(() => [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex h-16 flex-shrink-0 items-center px-4" }, [
          _createElementVNode("div", { class: "shine-block bg-gray-600 h-5 w-32" })
        ], -1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
              return _createElementVNode("div", {
                key: i,
                class: "flex items-center"
              }, _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "h-6 w-6 bg-gray-600 rounded-md mr-5" }, null, -1),
                _createElementVNode("div", { class: "h-4 w-28 bg-gray-600 rounded-md" }, null, -1)
              ]))
            }), 64))
          ])
        ])
      ]),
      _: 1
    }, 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", _hoisted_5, [
          _createVNode(_unref(Bars3BottomLeftIcon), {
            class: "h-6 w-6",
            "aria-hidden": "true"
          })
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-1 justify-between px-4 sm:px-6 lg:px-10" }, [
          _createElementVNode("div", { class: "min-w-0 flex flex-1 items-center" }, [
            _createElementVNode("div", { class: "shine-block h-5 w-32" })
          ])
        ], -1))
      ]),
      _cache[3] || (_cache[3] = _createStaticVNode("<main class=\"flex flex-1 h-full\"><div class=\"w-full max-w-7xl px-4 py-10 sm:px-6 lg:px-10\"><div class=\"grid grid-cols-1 lg:grid-cols-6\"><div class=\"lg:col-span-5 xl:col-span-4\"><div class=\"shine-block h-5 w-32\"></div><div class=\"shine-block h-3 w-1/2 mt-3\"></div><div class=\"mt-8 grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-8 lg:gap-x-9\"><div class=\"shine-block h-32 sm:h-40 w-auto\"></div><div class=\"shine-block h-32 sm:h-40 w-auto\"></div></div></div><div class=\"lg:col-span-5 xl:col-span-4 mt-12\"><div class=\"shine-block h-5 w-32\"></div><div class=\"shine-block h-3 w-1/2 mt-3\"></div><div class=\"shine-block h-72 w-full mt-8\"></div></div></div></div></main>", 1))
    ])
  ]))
}
}

})