const noop = () => { /* do nothing */ };

export const useGtag = () => {
  if ('gtag' in window) {
    return window.gtag as Gtag.Gtag;
  }

  console.info('GA Disabled');
  return noop as Gtag.Gtag;
};
