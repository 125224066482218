import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "hidden sm:grid sm:grid-cols-2 gap-x-1.5 gap-y-2 text-sm text-gray-600" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-y-1.5 sm:hidden text-xs text-gray-600 items-center" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { Plan, PlanId } from '@/models';

interface Props {
  plan: PlanId;
}

interface Feature {
  name: string;
  enabled: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SubFeaturesList',
  props: {
    plan: {}
  },
  setup(__props: any) {

const { t } = useI18n();
const props = __props;

const featureList = computed<Feature[]>(() => {
  const isPro = Plan.oneOfPro(props.plan);

  return [
    {
      name: t('billing.subscription.feature.basicPrompts'),
      enabled: true,
    },
    {
      name: t('billing.subscription.feature.proPrompts'),
      enabled: isPro,
    },
    {
      name: t('billing.subscription.feature.customPrompts'),
      enabled: isPro,
    },
    {
      name: t('billing.subscription.feature.favoritePrompts'),
      enabled: isPro,
    },
    {
      name: t('billing.subscription.feature.teamSharing'),
      enabled: isPro,
    },
  ];
});

const shortFeatureList = computed<Feature[]>(() => {
  const isPro = Plan.oneOfPro(props.plan);

  return [
    {
      name: isPro
        ? t('billing.subscription.feature.allPrompts')
        : t('billing.subscription.feature.basicPrompts'),
      enabled: true,
    },
    {
      name: t('billing.subscription.feature.unlimitedPrompts'),
      enabled: isPro,
    },
    {
      name: t('billing.subscription.feature.teamSharing'),
      enabled: isPro,
    },
  ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(featureList.value, (feature) => {
        return (_openBlock(), _createElementBlock("div", {
          key: feature.name,
          class: "flex items-center space-x-1.5"
        }, [
          (feature.enabled)
            ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                key: 0,
                class: "w-4 h-4 stroke-2 stroke-green-500 shrink-0"
              }))
            : (_openBlock(), _createBlock(_unref(XMarkIcon), {
                key: 1,
                class: "w-4 h-4 stroke-2 stroke-red-400 shrink-0"
              })),
          _createElementVNode("span", null, _toDisplayString(feature.name), 1)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shortFeatureList.value, (feature) => {
        return (_openBlock(), _createElementBlock("div", {
          key: feature.name,
          class: "flex space-x-1 items-center"
        }, [
          (feature.enabled)
            ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                key: 0,
                class: "w-3 h-3 stroke-green-500 shrink-0"
              }))
            : (_openBlock(), _createBlock(_unref(XMarkIcon), {
                key: 1,
                class: "w-3 h-3 stroke-red-400 shrink-0"
              })),
          _createElementVNode("div", null, _toDisplayString(feature.name), 1)
        ]))
      }), 128))
    ])
  ]))
}
}

})