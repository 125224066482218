import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-lg font-semibold leading-6 text-gray-900" }
const _hoisted_2 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_3 = { class: "mt-5" }
const _hoisted_4 = { class: "grid grid-cols-1 sm:grid-cols-3" }
const _hoisted_5 = { class: "sm:col-span-2" }
const _hoisted_6 = { class: "sm:col-span-2" }
const _hoisted_7 = { class: "sm:col-span-2" }
const _hoisted_8 = { class: "mt-8 flex space-x-3" }

import { ref, VueElement } from 'vue';
import { Container } from 'typedi';
import validator from 'validator';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import PasswordField from '@/components/Form/PasswordField.vue';
import Button from '@/components/Core/Button.vue';
import StatusMessage from '@/components/Form/StatusMessage.vue';
import { AppError, ErrorCode } from '@/lib/error';


export default /*@__PURE__*/_defineComponent({
  __name: 'UserPasswordForm',
  setup(__props) {

const { passwordRules } = Container.get('config.app');
const authStore = useAuthStore();
const { t } = useI18n();

const loading = ref(false);
const showForm = ref(false);
const status = ref<'success' | 'error'>();

const oldPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');

const newPasswordField = ref<VueElement>();
const confirmPasswordField = ref<VueElement>();

const oldPasswordError = ref<string>();
const newPasswordError = ref<string>();
const confirmPasswordError = ref<string>();

const reset = () => {
  oldPassword.value = '';
  newPassword.value = '';
  confirmPassword.value = '';

  oldPasswordError.value = undefined;
  newPasswordError.value = undefined;
  confirmPasswordError.value = undefined;
};

const validateOldPassword = () => {
  oldPassword.value = oldPassword.value.trim();

  if (!oldPassword.value) {
    oldPasswordError.value = t('form.input.any.error.required');
  } else {
    oldPasswordError.value = undefined;
  }

  return !oldPasswordError.value;
};

const validateNewPassword = (): boolean => {
  newPassword.value = newPassword.value.trim();

  if (!newPassword.value) {
    newPasswordError.value = t('form.input.any.error.required');
  } else if (newPassword.value === oldPassword.value) {
    newPasswordError.value = t('form.input.newPassword.error.samePassword');
  } else if (!validator.isStrongPassword(newPassword.value, {
    ...passwordRules,
    returnScore: false,
  })) {
    newPasswordError.value = t('form.input.password.error.weak');
  } else {
    newPasswordError.value = undefined;
  }

  return !newPasswordError.value;
};

const validateConfirmPassword = (): boolean => {
  confirmPassword.value = confirmPassword.value.trim();

  if (!confirmPassword.value) {
    confirmPasswordError.value = t('form.input.any.error.required');
  } else if (confirmPassword.value !== newPassword.value) {
    confirmPasswordError.value = t('form.input.confirmPassword.error.mismatch');
  } else {
    confirmPasswordError.value = undefined;
  }

  return !confirmPasswordError.value;
};

const handleSave = async () => {
  const ok = validateOldPassword() && validateNewPassword() && validateConfirmPassword();

  if (!ok) {
    return;
  }

  loading.value = true;
  status.value = undefined;

  const err = await authStore.changePassword(oldPassword.value, newPassword.value);

  switch (AppError.getCode(err)) {
    case null:
      status.value = 'success';
      reset();
      break;
    case ErrorCode.Invalid: {
      newPasswordError.value = t('form.input.any.error.invalid');
      break;
    }
    case ErrorCode.Unauthorized:
      oldPasswordError.value = t('form.input.oldPassword.error.invalid');
      break;
    default:
      status.value = 'error';
      break;
  }

  loading.value = false;
};

const handleCancel = () => {
  reset();
  status.value = undefined;
  showForm.value = false;
};

const handleOldPasswordChange = (byEnter: boolean) => {
  if (validateOldPassword() && byEnter) {
    newPasswordField.value?.focus();
  }
};

const handleNewPasswordChange = (byEnter: boolean) => {
  if (validateNewPassword() && byEnter) {
    confirmPasswordField.value?.focus();
  }
};

const handleConfirmPasswordChange = () => {
  validateConfirmPassword();
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('profile.password.title')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('profile.password.text')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (showForm.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(PasswordField, {
                  modelValue: oldPassword.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((oldPassword).value = $event)),
                  "label-text": _ctx.$t('form.input.oldPassword.label'),
                  error: oldPasswordError.value,
                  onInput: _cache[1] || (_cache[1] = ($event: any) => (oldPasswordError.value = undefined)),
                  onKeyup: _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (handleOldPasswordChange(true)), ["prevent"]), ["enter"])),
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (handleOldPasswordChange(false)))
                }, null, 8, ["modelValue", "label-text", "error"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(PasswordField, {
                  modelValue: newPassword.value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((newPassword).value = $event)),
                  ref_key: "newPasswordField",
                  ref: newPasswordField,
                  class: "mt-6",
                  "label-text": _ctx.$t('form.input.newPassword.label'),
                  error: newPasswordError.value,
                  onInput: _cache[5] || (_cache[5] = ($event: any) => (newPasswordError.value = undefined)),
                  onKeyup: _cache[6] || (_cache[6] = _withKeys(_withModifiers(($event: any) => (handleNewPasswordChange(true)), ["prevent"]), ["enter"])),
                  onChange: _cache[7] || (_cache[7] = ($event: any) => (handleNewPasswordChange(false)))
                }, null, 8, ["modelValue", "label-text", "error"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(PasswordField, {
                  modelValue: confirmPassword.value,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((confirmPassword).value = $event)),
                  ref_key: "confirmPasswordField",
                  ref: confirmPasswordField,
                  class: "mt-6",
                  "label-text": _ctx.$t('form.input.confirmPassword.label'),
                  error: confirmPasswordError.value,
                  onInput: _cache[9] || (_cache[9] = ($event: any) => (confirmPasswordError.value = undefined)),
                  onKeyup: _cache[10] || (_cache[10] = _withKeys(_withModifiers(($event: any) => (handleConfirmPasswordChange()), ["prevent"]), ["enter"])),
                  onChange: _cache[11] || (_cache[11] = ($event: any) => (handleConfirmPasswordChange()))
                }, null, 8, ["modelValue", "label-text", "error"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(Button, {
                class: "btn-sm",
                disabled: loading.value,
                onClick: handleSave,
                onKeyup: _withKeys(_withModifiers(handleSave, ["prevent"]), ["enter"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.btn.save')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onKeyup"]),
              _createVNode(Button, {
                class: "btn-sm btn-bordered",
                disabled: loading.value,
                onClick: handleCancel,
                onKeyup: _withKeys(_withModifiers(handleCancel, ["prevent"]), ["enter"])
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.btn.cancel')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onKeyup"]),
              _createVNode(StatusMessage, {
                show: !!status.value,
                type: status.value!
              }, {
                default: _withCtx(() => [
                  (status.value === 'success')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('profile.password.passwordChanged')), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["show", "type"])
            ])
          ], 64))
        : (_openBlock(), _createBlock(Button, {
            key: 1,
            class: "btn-sm",
            onClick: _cache[12] || (_cache[12] = ($event: any) => (showForm.value = true)),
            onKeyup: _cache[13] || (_cache[13] = _withKeys(_withModifiers(($event: any) => (showForm.value = true), ["prevent"]), ["enter"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('profile.password.btn.changePassword')), 1)
            ]),
            _: 1
          }))
    ])
  ], 64))
}
}

})