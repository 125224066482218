import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "inline-block min-w-full py-2 align-middle sm:px-4 md:px-6 lg:px-8" }
const _hoisted_2 = { class: "overflow-hidden sm:shadow ring-1 ring-gray-200 sm:rounded-lg" }
const _hoisted_3 = { class: "min-w-full sm:divide-y divide-gray-200" }
const _hoisted_4 = { class: "bg-gray-50" }
const _hoisted_5 = { class: "hidden sm:table-row" }
const _hoisted_6 = {
  scope: "col",
  class: "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
}
const _hoisted_7 = {
  scope: "col",
  class: "hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_8 = {
  scope: "col",
  class: "hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
}
const _hoisted_9 = {
  scope: "col",
  class: "relative py-3.5 pl-3 pr-4 sm:pr-6"
}
const _hoisted_10 = { class: "sr-only" }
const _hoisted_11 = { class: "divide-y divide-gray-200 bg-white" }
const _hoisted_12 = { class: "whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6" }
const _hoisted_13 = { class: "flex items-center" }
const _hoisted_14 = { class: "flex-shrink-0" }
const _hoisted_15 = { class: "ml-3 sm:ml-4" }
const _hoisted_16 = { class: "font-medium text-gray-900" }
const _hoisted_17 = { class: "text-gray-500 truncate outline-none no-underline" }
const _hoisted_18 = { class: "sm:hidden flex items-center gap-2 mt-1" }
const _hoisted_19 = { class: "text-xs font-medium text-gray-700" }
const _hoisted_20 = { class: "hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500" }
const _hoisted_21 = { class: "hidden sm:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500" }
const _hoisted_22 = { class: "relative whitespace-nowrap py-4 pl-3 pr-4 text-center sm:text-right text-sm font-medium sm:pr-6" }


import UserRoleLabel from '@/components/User/UserRoleLabel.vue';
import { UserRole, UserStatus } from '@/models/user.model';
import Avatar from '@/components/User/Avatar.vue';
import UserStatusLabel from '@/components/User/UserStatusLabel.vue';

interface UserInfo {
  id: string;
  name?: string;
  email: string;
  role: UserRole;
  status: UserStatus;
}

interface Props {
  allowManage: boolean;
  users: UserInfo[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserTable',
  props: {
    allowManage: { type: Boolean, default: false },
    users: { default: () => [] }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", _hoisted_4, [
          _createElementVNode("tr", _hoisted_5, [
            _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t('page.team.userTable.col.name')), 1),
            _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t('page.team.userTable.col.status')), 1),
            _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t('page.team.userTable.col.accessRights')), 1),
            _createElementVNode("th", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('page.team.userTable.col.actions')), 1)
            ])
          ])
        ]),
        _createElementVNode("tbody", _hoisted_11, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: user.email
            }, [
              _createElementVNode("td", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(Avatar, {
                      size: "md",
                      seed: user.id,
                      userName: user.name || user.email
                    }, null, 8, ["seed", "userName"])
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(user.name), 1),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(user.email), 1),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, [
                        _createVNode(UserRoleLabel, {
                          role: user.role
                        }, null, 8, ["role"])
                      ]),
                      _createVNode(UserStatusLabel, {
                        status: user.status
                      }, null, 8, ["status"])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("td", _hoisted_20, [
                _createVNode(UserStatusLabel, {
                  status: user.status
                }, null, 8, ["status"])
              ]),
              _createElementVNode("td", _hoisted_21, [
                _createVNode(UserRoleLabel, {
                  role: user.role
                }, null, 8, ["role"])
              ]),
              _createElementVNode("td", _hoisted_22, [
                _withDirectives(_createVNode(_component_router_link, {
                  to: `/user/${user.id}`,
                  class: "text-indigo-600 hover:text-indigo-900"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.btn.edit')), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]), [
                  [_vShow, _ctx.allowManage]
                ])
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}
}

})