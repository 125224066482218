import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pointer-events-auto w-full max-w-sm sm:max-w-xs overflow-hidden rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5" }
const _hoisted_2 = { class: "p-4" }
const _hoisted_3 = { class: "flex items-start" }
const _hoisted_4 = { class: "flex-shrink-0" }
const _hoisted_5 = { class: "ml-3 w-0 flex-1 pt-0.5" }
const _hoisted_6 = { class: "text-sm font-medium text-gray-900" }
const _hoisted_7 = {
  key: 0,
  class: "mt-1 text-sm text-gray-500"
}
const _hoisted_8 = { class: "ml-4 flex flex-shrink-0" }
const _hoisted_9 = { class: "sr-only" }

import { onBeforeMount } from 'vue';
import { Notification, NotificationType } from '@/models';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';

interface Props {
  notification: Notification
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Notification',
  props: {
    notification: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
let icon = InformationCircleIcon;
let iconColor = 'text-blue-400';

onBeforeMount(() => {
  switch (props.notification.type) {
    case NotificationType.Success:
      icon = CheckCircleIcon;
      iconColor = 'text-green-400';
      break;
    case NotificationType.Error:
      icon = XCircleIcon;
      iconColor = 'text-red-400';
      break;
    case NotificationType.Warning:
      icon = ExclamationTriangleIcon;
      iconColor = 'text-yellow-400';
      break;
    default:
      icon = InformationCircleIcon;
      iconColor = 'text-blue-400';
      break;
  }
});

const handleClose = () => {
  emit('close', props.notification.id);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(icon)), {
                class: _normalizeClass(["h-6 w-6", _unref(iconColor)]),
                "aria-hidden": "true"
              }, null, 8, ["class"]))
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.notification.title), 1),
              (_ctx.notification.text)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.notification.text), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                type: "button",
                onClick: handleClose,
                class: "inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 active:text-gray-500 focus:outline-none"
              }, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('common.btn.close')), 1),
                _createVNode(_unref(XMarkIcon), {
                  class: "h-5 w-5",
                  "aria-hidden": "true"
                })
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})