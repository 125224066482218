import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';
import { useAccountStore } from '@/stores/account';
import { useBillingStore } from '@/stores/billing';
import { usePageViews } from '@/composables/page-view';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const authStore = useAuthStore();
const userStore = useUserStore();
const billingStore = useBillingStore();
const accountStore = useAccountStore();

usePageViews();

watch(() => authStore.isAuthenticated, (value, oldValue) => {
  const logout = oldValue && !value;

  if (logout) {
    userStore.reset();
    billingStore.reset();
    accountStore.reset();
    authStore.reset();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createBlock(_component_RouterView))
}
}

})