import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 lg:grid-cols-6" }
const _hoisted_2 = { class: "lg:col-span-5 xl:col-span-4" }
const _hoisted_3 = {
  key: 0,
  class: "mb-16"
}
const _hoisted_4 = { class: "lg:col-span-5 xl:col-span-4" }
const _hoisted_5 = { class: "sm:flex sm:items-center" }
const _hoisted_6 = { class: "sm:flex-auto" }
const _hoisted_7 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_8 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_9 = { class: "mt-6 flex flex-col" }
const _hoisted_10 = { class: "-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8" }

import {
  computed,
  onBeforeMount,
  ref,
  VueElement,
} from 'vue';
import { isLeft } from '@/lib/either';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { useBillingStore } from '@/stores/billing';
import { AppError, ErrorCode } from '@/lib/error';
import InviteForm from '@/components/Team/InviteForm.vue';
import UserTable from '@/components/Team/UserTable.vue';
import PageContent from '@/components/Layout/PageContent.vue';
import isEmail from 'validator/lib/isEmail';


export default /*@__PURE__*/_defineComponent({
  __name: 'TeamPage',
  setup(__props) {

const userStore = useUserStore();
const billingStore = useBillingStore();
const { t } = useI18n();

const loading = ref(false);
const errorCode = ref<ErrorCode>();
const inviteForm = ref<VueElement & { success(): void }>();
const inviteError = ref<string>();
const inviteLoading = ref(false);

const users = computed(() => userStore.sortedUserList);
const currentUserAdmin = computed(() => userStore.currentUser.isAdmin());
const subscription = computed(() => billingStore.subscription);

onBeforeMount(async () => {
  const hasManyUsers = users.value.length > 1;
  loading.value = !hasManyUsers;

  const result = await userStore.loadUsers();

  if (isLeft(result) && !hasManyUsers) {
    errorCode.value = ErrorCode.Internal;
    return;
  }

  loading.value = false;
});

const handleInviteInput = () => {
  inviteError.value = undefined;
};

const handleInviteSubmit = async (email: string) => {
  const cleanEmail = email.trim();

  if (!cleanEmail) {
    inviteError.value = t('form.input.any.error.required');
    return;
  }

  if (!isEmail(cleanEmail)) {
    inviteError.value = t('form.input.email.error.invalid');
    return;
  }

  inviteLoading.value = true;
  inviteError.value = undefined;
  const result = await userStore.inviteUser(cleanEmail);
  const err = isLeft(result) ? result.left : null;

  switch (AppError.getCode(err)) {
    case null:
      inviteForm.value?.success();
      break;
    case ErrorCode.Invalid:
      inviteError.value = t('form.input.email.error.invalid');
      break;
    case ErrorCode.Conflict:
      inviteError.value = t('form.input.email.error.exists');
      break;
    case ErrorCode.LimitReached:
      inviteError.value = t('page.team.inviteForm.error.limit');
      break;
    default:
      inviteError.value = t('common.error.tech');
      break;
  }

  inviteLoading.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageContent, {
    loading: loading.value,
    "error-code": errorCode.value
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (currentUserAdmin.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(InviteForm, {
                  ref_key: "inviteForm",
                  ref: inviteForm,
                  loading: inviteLoading.value,
                  error: inviteError.value,
                  userLimit: !subscription.value?.hasFreeSeats(),
                  onInput: handleInviteInput,
                  onSubmit: handleInviteSubmit
                }, null, 8, ["loading", "error", "userLimit"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t('page.team.userTable.title')), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('page.team.userTable.text')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(UserTable, {
                users: users.value,
                "allow-manage": currentUserAdmin.value
              }, null, 8, ["users", "allow-manage"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading", "error-code"]))
}
}

})