import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-10 divide-y divide-gray-200 grid grid-cols-1 lg:grid-cols-6" }
const _hoisted_2 = { class: "col-span-6 lg:col-span-5 xl:col-span-4" }
const _hoisted_3 = { class: "col-span-6 lg:col-span-5 xl:col-span-4 pt-8" }
const _hoisted_4 = { class: "col-span-6 lg:col-span-5 xl:col-span-4 pt-8" }

import { computed, onBeforeMount, ref } from 'vue';
import { isLeft } from '@/lib/either';
import { ErrorCode } from '@/lib/error';
import { useUserStore } from '@/stores/user';
import PageContent from '@/components/Layout/PageContent.vue';
import DashboardWelcome from './DashboardWelcome.vue';
import DashboardTeam from './DashboardTeam.vue';
import DashboardBilling from './DashboardBilling.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardPage',
  setup(__props) {

const userStore = useUserStore();

const loading = ref(false);
const errorCode = ref<ErrorCode>();

const users = computed(() => userStore.sortedUserList);

onBeforeMount(async () => {
  const hasManyUsers = users.value.length > 1;
  loading.value = !hasManyUsers;

  const result = await userStore.loadUsers();

  if (isLeft(result) && !hasManyUsers) {
    errorCode.value = ErrorCode.Internal;
    return;
  }

  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageContent, {
    loading: loading.value,
    "error-code": errorCode.value
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(DashboardWelcome)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(DashboardTeam)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(DashboardBilling)
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading", "error-code"]))
}
}

})