import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-6 -mb-3 text-center text-sm text-red-500"
}
const _hoisted_2 = { class: "relative mt-5" }
const _hoisted_3 = { class: "relative flex justify-center text-sm leading-6" }
const _hoisted_4 = { class: "bg-white px-4 text-gray-500" }
const _hoisted_5 = { class: "mt-5" }
const _hoisted_6 = { class: "text-sm font-semibold leading-6" }
const _hoisted_7 = { class: "mt-4 text-sm text-center text-gray-500 w-4/5 mx-auto" }
const _hoisted_8 = {
  href: "https://cherrytree.ai/terms",
  target: "_blank",
  class: "link"
}
const _hoisted_9 = {
  href: "https://cherrytree.ai/privacy-policy",
  target: "_blank",
  class: "link"
}
const _hoisted_10 = {
  href: "mailto:help@cherrytree.ai",
  class: "btn btn-bordered"
}

import {
  ref,
  computed,
  onBeforeMount,
  VueElement,
} from 'vue';
import { Container } from 'typedi';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useAuthStore, VerifyInviteResult } from '@/stores/auth';
import validator from 'validator';
import { AuthRouteName } from '@/router/route.names';
import { AppError, ErrorCode } from '@/lib/error';
import AuthPage from '@/components/Auth/AuthPage.vue';
import TextField from '@/components/Form/TextField.vue';
import PasswordField from '@/components/Form/PasswordField.vue';
import ErrorPage from '@/components/Error/ErrorPage.vue';
import Button from '@/components/Core/Button.vue';
import Loader from '@/components/Core/Loader.vue';
import GoogleIcon from '@/components/Core/Icons/GoogleIcon.vue';

interface Props {
  inviteToken?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SignupPage',
  props: {
    inviteToken: {}
  },
  setup(__props: any) {

const { passwordRules } = Container.get('config.app');
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();
const authStore = useAuthStore();

const props = __props;

const loading = ref(false);
const googleLoading = ref(false);
const email = ref('');
const password = ref('');

const invalidInviteToken = ref(false);
const emailError = ref<string>();
const passwordError = ref<string>();
const formError = ref<string>();

const emailField = ref<VueElement>();
const passwordField = ref<VueElement>();

const formComplete = computed<boolean>(() => (
  email.value.length > 0 && password.value.length > 0
));

const hasErrors = computed<boolean>(() => (
  !!emailError.value || !!passwordError.value
));

const withInvite = computed<boolean>(() => !!props.inviteToken);

onBeforeMount(async () => {
  if (!props.inviteToken) {
    return;
  }

  loading.value = true;
  const result = await authStore.verifyInvite(props.inviteToken);

  switch (AppError.getCode(result)) {
    case null: {
      const { email: inviteEmail } = result as VerifyInviteResult;
      email.value = inviteEmail;
      break;
    }
    case ErrorCode.LimitReached:
      await router.replace({ name: AuthRouteName.Login });
      break;
    default:
      invalidInviteToken.value = true;
      break;
  }

  loading.value = false;
});

const validateEmail = (): boolean => {
  email.value = email.value.trim();

  if (!email.value) {
    emailError.value = t('form.input.any.error.required');
  } else if (!validator.isEmail(email.value)) {
    emailError.value = t('form.input.email.error.invalid');
  } else {
    emailError.value = undefined;
  }

  return !emailError.value;
};

const validatePassword = (): boolean => {
  password.value = password.value.trim();

  if (!password.value) {
    passwordError.value = t('form.input.any.error.required');
  } else if (!validator.isStrongPassword(password.value, {
    ...passwordRules,
    returnScore: false,
  })) {
    passwordError.value = t('form.input.password.error.weak');
  } else {
    passwordError.value = undefined;
  }

  return !passwordError.value;
};

const signUp = async (): Promise<void> => {
  const err = await authStore.singUp(email.value, password.value);

  switch (AppError.getCode(err)) {
    case null:
      break;
    case ErrorCode.Conflict:
      emailError.value = t('form.input.email.error.exists');
      break;
    case ErrorCode.Invalid: {
      const msg = err?.message || '';
      let found = false;

      if (msg.includes('email:')) {
        emailError.value = t('form.input.email.error.invalid');
        found = true;
      }

      if (msg.includes('password:')) {
        passwordError.value = t('form.input.password.error.weak');
        found = true;
      }

      if (!found) {
        formError.value = t('auth.signup.step1.error.invalid');
      }

      break;
    }
    default:
      formError.value = t('common.error.tech');
      break;
  }
};

const acceptInvite = async (): Promise<void> => {
  const err = await authStore.acceptInvite(props.inviteToken!, password.value);

  switch (AppError.getCode(err)) {
    case null:
      break;
    case ErrorCode.Invalid: {
      const msg = err?.message || '';
      let found = false;

      if (msg.includes('password:')) {
        passwordError.value = t('form.input.password.error.weak');
        found = true;
      }

      if (!found) {
        formError.value = t('auth.signup.step1.error.invalid');
      }

      break;
    }
    default:
      formError.value = t('common.error.tech');
      break;
  }
};

const handleSubmit = async (): Promise<void> => {
  if (loading.value) {
    return;
  }

  validateEmail();
  validatePassword();

  if (hasErrors.value || !formComplete.value) {
    return;
  }

  loading.value = true;
  formError.value = undefined;
  emailError.value = undefined;
  passwordError.value = undefined;

  if (withInvite.value) {
    await acceptInvite();
  } else {
    await signUp();
  }

  loading.value = false;
};

const handleEmailInput = (): void => {
  emailError.value = undefined;
};

const handleEmailChange = (byEnter: boolean): void => {
  const ok = validateEmail();

  if (byEnter && ok) {
    passwordField.value?.focus();
  }
};

const handlePasswordInput = (): void => {
  passwordError.value = undefined;
};

const handlePasswordChange = (byEnter: boolean): void => {
  const ok = validatePassword();

  if (byEnter && ok) {
    handleSubmit();
  }
};

const handleGoogleSignUp = () => {
  const authUrl = authStore.startGoogleAuth(AuthRouteName.Signup);
  googleLoading.value = true;

  setTimeout(() => {
    window.open(authUrl, '_self')?.focus();
    googleLoading.value = false;
  }, 200);
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (!invalidInviteToken.value)
    ? (_openBlock(), _createBlock(AuthPage, { key: 0 }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.signup.step1.title')), 1)
        ]),
        subtitle: _withCtx(() => [
          _createVNode(_component_i18n_t, {
            keypath: "auth.signup.step1.haveAccount",
            tag: "span"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: _unref(AuthRouteName).Login },
                class: "link"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('auth.signup.step1.haveAccount.link')), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _: 1
          })
        ]),
        form: _withCtx(() => [
          _createVNode(TextField, {
            modelValue: email.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
            ref_key: "emailField",
            ref: emailField,
            "input-type": "email",
            "label-text": _ctx.$t('form.input.emailAddress.label'),
            class: "mt-10",
            error: emailError.value,
            disabled: withInvite.value && email.value.length > 0,
            autocomplete: "",
            onInput: handleEmailInput,
            onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (handleEmailChange(true)), ["prevent"]), ["enter"])),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (handleEmailChange(false)))
          }, null, 8, ["modelValue", "label-text", "error", "disabled"]),
          _createVNode(PasswordField, {
            modelValue: password.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password).value = $event)),
            ref_key: "passwordField",
            ref: passwordField,
            class: "mt-6",
            "label-text": _ctx.$t('form.input.password.label'),
            error: passwordError.value,
            onInput: handlePasswordInput,
            onKeyup: _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (handlePasswordChange(true)), ["prevent"]), ["enter"])),
            onChange: _cache[5] || (_cache[5] = ($event: any) => (handlePasswordChange(false)))
          }, null, 8, ["modelValue", "label-text", "error"]),
          (formError.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(formError.value), 1))
            : _createCommentVNode("", true),
          _createVNode(Button, {
            class: "w-full mt-10",
            onClick: handleSubmit,
            onKeyup: _withKeys(_withModifiers(handleSubmit, ["prevent"]), ["enter"])
          }, {
            default: _withCtx(() => [
              (loading.value)
                ? (_openBlock(), _createBlock(Loader, {
                    key: 0,
                    class: "text-white"
                  }))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.btn.continue')), 1)
                  ], 64))
            ]),
            _: 1
          }, 8, ["onKeyup"]),
          _createElementVNode("div", _hoisted_2, [
            _cache[6] || (_cache[6] = _createElementVNode("div", {
              class: "absolute inset-0 flex items-center",
              "aria-hidden": "true"
            }, [
              _createElementVNode("div", { class: "w-full border-t border-gray-200" })
            ], -1)),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('common.text.or')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(Button, {
              class: "btn-bordered w-full",
              loading: googleLoading.value,
              onClick: handleGoogleSignUp
            }, {
              default: _withCtx(() => [
                _createVNode(GoogleIcon, { class: "w-5 h-5 mr-3" }),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('auth.signup.btn.signUpWithGoogle')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_i18n_t, {
              keypath: "auth.signup.step1.terms",
              tag: "span",
              class: "select-none"
            }, {
              default: _withCtx(() => [
                _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('auth.signup.step1.terms.tos')), 1),
                _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t('auth.signup.step1.terms.pp')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(ErrorPage, { key: 1 }, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.signup.withInvite.invalidToken.title')), 1)
        ]),
        text: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('auth.signup.withInvite.invalidToken.text')), 1)
        ]),
        actions: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: { name: _unref(AuthRouteName).Login },
            class: "btn btn-bordered"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('auth.signup.withInvite.invalidToken.btnLogin')), 1)
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("a", _hoisted_10, _toDisplayString(_ctx.$t('common.btn.support')), 1)
        ]),
        _: 1
      }))
}
}

})