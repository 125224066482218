import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-xl font-semibold flex items-center" }

import { useI18n } from 'vue-i18n';
import SubscriptionCard from '@/components/Billing/Subscription/SubscriptionCard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardBilling',
  setup(__props) {

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_unref(t)('page.dashboard.billing.title')), 1),
    _createVNode(SubscriptionCard, {
      class: "mt-5 w-full md:w-4/5 lg:w-full xl:w-4/5",
      showUnsubscribe: false
    })
  ]))
}
}

})