import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref } from 'vue';
import ResetPasswordStep1 from '@/components/Auth/ResetPassword/ResetPasswordStep1.vue';
import ResetPasswordStep2 from '@/components/Auth/ResetPassword/ResetPasswordStep2.vue';
import ResetPasswordStep3 from '@/components/Auth/ResetPassword/ResetPasswordStep3.vue';

interface Props {
  resetToken?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordView',
  props: {
    resetToken: {}
  },
  setup(__props: any) {

const props = __props;
const step = ref(props.resetToken ? 3 : 1);

return (_ctx: any,_cache: any) => {
  return (step.value === 3)
    ? (_openBlock(), _createBlock(ResetPasswordStep3, {
        key: 0,
        resetToken: _ctx.resetToken
      }, null, 8, ["resetToken"]))
    : (step.value === 2)
      ? (_openBlock(), _createBlock(ResetPasswordStep2, { key: 1 }))
      : (_openBlock(), _createBlock(ResetPasswordStep1, {
          key: 2,
          onSuccess: _cache[0] || (_cache[0] = ($event: any) => (step.value = 2))
        }))
}
}

})