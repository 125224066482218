import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id", "type", "placeholder", "value", "disabled"]
const _hoisted_2 = { class: "absolute flex items-center inset-y-0 right-0 pr-3 select-none" }

import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline';
import FormElement from '@/components/Form/FormElement.vue';

import useUniqueId from '@/composables/uid';
import {
  nextTick,
  ref,
} from 'vue';

interface Props {
  modelValue: string;
  labelText?: string;
  placeholderText?: string;
  disabled?: boolean;
  hint?: string;
  loading?: boolean;
  error?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PasswordField',
  props: {
    modelValue: { default: '' },
    labelText: {},
    placeholderText: {},
    disabled: { type: Boolean, default: false },
    hint: {},
    loading: { type: Boolean, default: false },
    error: {}
  },
  setup(__props: any, { expose: __expose }) {

const uid = useUniqueId();
const props = __props;

const hideValue = ref(true);
const inputElem = ref<HTMLInputElement | null>(null);

const handleEyeClick = () => {
  hideValue.value = !hideValue.value;
  const len = props.modelValue.length;
  const input = inputElem.value;

  if (len > 0 && input) {
    nextTick(() => {
      input.focus();
      input.setSelectionRange(len, len);
    });
  }
};

const focus = () => inputElem.value?.focus();
__expose({ focus });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FormElement, {
    id: _unref(uid),
    "label-text": _ctx.labelText,
    error: _ctx.error,
    hint: _ctx.hint,
    disabled: _ctx.disabled
  }, {
    control: _withCtx(() => [
      _createElementVNode("input", {
        id: _unref(uid),
        type: hideValue.value ? 'password' : 'text',
        ref_key: "inputElem",
        ref: inputElem,
        placeholder: _ctx.placeholderText,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        class: "block w-full mt-1 pl-3 py-2 h-10 pr-10 sm:text-sm",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }, null, 40, _hoisted_1)
    ]),
    icon: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (hideValue.value)
          ? (_openBlock(), _createBlock(_unref(EyeIcon), {
              key: 0,
              class: "cursor-pointer",
              onClick: handleEyeClick
            }))
          : (_openBlock(), _createBlock(_unref(EyeSlashIcon), {
              key: 1,
              class: "cursor-pointer",
              onClick: handleEyeClick
            }))
      ])
    ]),
    _: 1
  }, 8, ["id", "label-text", "error", "hint", "disabled"]))
}
}

})