import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-xs text-gray-600 items-center space-y-1.5" }
const _hoisted_2 = { class: "flex space-x-1 items-center" }
const _hoisted_3 = { class: "flex space-x-1 items-center" }
const _hoisted_4 = { class: "flex space-x-1 items-center" }

import { useI18n } from 'vue-i18n';
import { CheckIcon } from '@heroicons/vue/24/solid';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlanFeatureList',
  setup(__props) {

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(CheckIcon), { class: "w-3 h-3 stroke-green-500" }),
      _createElementVNode("div", null, _toDisplayString(_unref(t)('billing.subscription.feature.allPrompts')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(CheckIcon), { class: "w-3 h-3 stroke-green-500" }),
      _createElementVNode("div", null, _toDisplayString(_unref(t)('billing.subscription.feature.unlimitedPrompts')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_unref(CheckIcon), { class: "w-3 h-3 stroke-green-500" }),
      _createElementVNode("div", null, _toDisplayString(_unref(t)('billing.subscription.feature.teamSharing')), 1)
    ])
  ]))
}
}

})