import { computed, ref } from 'vue';
import { Router } from 'vue-router';
import { defineStore } from 'pinia';
import { Container } from 'typedi';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { AppError, ErrorCode, ErrorSub } from '@/lib/error';
import { isLeft } from '@/lib/either';
import { RpcMethod, useExtensionCall } from '@/composables/extension';
import { AuthProvider } from '@/provider/api';
import { AuthRouteName, PageRouteName } from '@/router/route.names';
import { NotificationType, UserRole } from '@/models';
import { useBrowserName, useDeviceType } from '@/composables/device-type';
import { useAnalytics } from '@/composables/analytics';
import { useNotificationStore } from '@/stores/notification';
import i18n from '@/i18n';
import { VerifyInviteResult } from './auth.types';

const TOKEN_TTL_GAP = 60; // seconds

interface CherryJwtPayload extends JwtPayload {
  sub: string;
  aid: string;
  uro: UserRole;
  exp: number;
  iat: number;
}

export const useAuthStore = defineStore('auth', () => {
  const { t } = i18n.global;
  const authProvider = Container.get(AuthProvider);
  const router = Container.get<Router>('router');
  const analytics = useAnalytics();
  const notification = useNotificationStore();
  const extension = useExtensionCall(
    process.env.VUE_APP_EXTENSION_ID,
  );

  const accountId = ref('');
  const userId = ref('');
  const userRole = ref(UserRole.User);
  const accessToken = ref('');
  const tokenIss = ref(0);
  const tokenTtl = ref(0);
  const lastEmail = ref('');

  const isAdmin = computed(() => userRole.value === UserRole.Admin);
  const isAuthenticated = computed(() => !!accessToken.value);

  function reset() {
    accountId.value = '';
    userId.value = '';
    userRole.value = UserRole.User;
    accessToken.value = '';
    tokenIss.value = 0;
    tokenTtl.value = 0;
  }

  function setAccessToken(newToken: string): void {
    const now = Math.floor(Date.now() / 1000);
    let payload;

    try {
      payload = jwtDecode<CherryJwtPayload>(newToken);
    } catch (err) {
      return;
    }

    accountId.value = payload.aid;
    userId.value = payload.sub;
    userRole.value = payload.uro;
    accessToken.value = newToken;
    tokenIss.value = now;
    tokenTtl.value = payload.exp - payload.iat;
  }

  function isTokenExpired(): boolean {
    const now = Math.floor(Date.now() / 1000);
    const tokenTs = tokenIss.value;
    const ttl = tokenTtl.value;

    if (!tokenTs || !ttl) {
      return true;
    }

    const diff = now - tokenTs + TOKEN_TTL_GAP;
    return diff >= ttl || Math.abs(diff) < TOKEN_TTL_GAP;
  }

  async function singUp(email: string, password: string): Promise<AppError | null> {
    analytics.event('start_sign_up', { method: 'email' });
    const result = await authProvider.signUp({ email, password });

    if (isLeft(result)) {
      analytics.event('fail_sign_up', {
        method: 'email',
        error: AppError.getCode(result.left),
      });

      return result.left;
    }

    lastEmail.value = email;
    setAccessToken(result.right.accessToken);

    const device = useDeviceType();
    const browserName = useBrowserName();

    if (device === 'desktop' && browserName === 'chrome') {
      let extError: AppError | null;

      try {
        extError = await extension.call({ method: RpcMethod.Ping, params: {} });
      } catch (err) {
        extError = AppError.newInternalError(ErrorSub.InternalError);
      }

      if (AppError.getCode(extError) === ErrorCode.NotFound) {
        analytics.event('complete_sign_up', { method: 'email' });
        await router.replace({ name: AuthRouteName.Onboarding });
        return null;
      }
    }

    analytics.event('complete_sign_up', { method: 'email' });
    await router.replace({ name: PageRouteName.Dashboard });
    return null;
  }

  async function signIn(email: string, password: string): Promise<AppError | null> {
    analytics.event('start_sign_in', { method: 'email' });
    const result = await authProvider.signIn({ email, password });

    if (isLeft(result)) {
      analytics.event('fail_sign_in', {
        method: 'email',
        error: AppError.getCode(result.left),
      });
      return result.left;
    }

    lastEmail.value = email;
    setAccessToken(result.right.accessToken);

    analytics.event('complete_sign_in', { method: 'email' });
    await router.replace({ name: PageRouteName.Dashboard });

    return null;
  }

  async function signOut(): Promise<AppError | null> {
    const result = await authProvider.signOut();
    const oldUserId = userId.value;
    reset();

    await router.replace({ name: AuthRouteName.Login });

    if (isLeft(result)) {
      return result.left;
    }

    await extension.call({
      method: RpcMethod.UserSignOut,
      params: { userId: oldUserId },
    });

    return null;
  }

  async function refreshToken(): Promise<AppError | null> {
    const result = await authProvider.refreshToken();

    if (isLeft(result)) {
      return result.left;
    }

    setAccessToken(result.right.accessToken);
    return null;
  }

  function startGoogleAuth(from: AuthRouteName): string {
    analytics.event(
      from === AuthRouteName.Login ? 'start_sign_in' : 'start_sign_up',
      { method: 'google' },
    );

    const url = process.env.VUE_APP_GOOGLE_AUTH_URL;
    const redirectUrl = `${process.env.VUE_APP_BASE_ADDRESS}/auth/status`;
    return `${url}?redirect_url=${redirectUrl}`;
  }

  async function handleProviderError(newUser: boolean, authErr: ErrorCode): Promise<void> {
    analytics.event(
      newUser ? 'fail_sign_up' : 'fail_sign_in',
      {
        method: 'google',
        error: authErr,
      },
    );

    notification.addNotification({
      type: NotificationType.Error,
      title: t('common.error.unknown'),
      text: t('common.error.unknown.text'),
    });

    await router.replace({ name: AuthRouteName.Login });
  }

  async function handleProviderAuth(newUser: boolean, authErr?: ErrorCode): Promise<void> {
    if (authErr) {
      await handleProviderError(newUser, authErr);
      return;
    }

    const refreshErr = await refreshToken();

    if (refreshErr) {
      await handleProviderError(newUser, AppError.getCode(refreshErr) as ErrorCode);
      return;
    }

    analytics.event(
      newUser ? 'complete_sign_up' : 'complete_sign_in',
      { method: 'google' },
    );

    await router.replace({
      name: newUser ? AuthRouteName.Onboarding : PageRouteName.Dashboard,
    });
  }

  async function startPasswordReset(email: string): Promise<AppError | null> {
    const result = await authProvider.createResetPasswordCode(email);

    if (isLeft(result)) {
      return result.left;
    }

    lastEmail.value = email;
    return null;
  }

  async function verifyResetPasswordToken(resetToken: string): Promise<AppError | null> {
    const result = await authProvider.verifyResetPasswordCode(resetToken);

    if (isLeft(result)) {
      return result.left;
    }

    return null;
  }

  async function resetPassword(restToken: string, password: string): Promise<AppError | null> {
    const result = await authProvider.resetPassword({ token: restToken, password });

    if (isLeft(result)) {
      return result.left;
    }

    return null;
  }

  async function changePassword(oldPassword: string, newPassword: string): Promise<AppError | null> {
    const result = await authProvider.changePassword({ oldPassword, newPassword });

    if (isLeft(result)) {
      return result.left;
    }

    setAccessToken(result.right.accessToken);
    return null;
  }

  async function verifyInvite(inviteToken: string): Promise<AppError | VerifyInviteResult> {
    const result = await authProvider.verifyUserInviteCode(inviteToken);

    if (isLeft(result)) {
      return result.left;
    }

    return result.right;
  }

  async function acceptInvite(
    inviteToken: string,
    password: string,
  ): Promise<AppError | null> {
    analytics.event('start_accept_invite');
    const result = await authProvider.acceptInvite({
      password,
      token: inviteToken,
    });

    if (isLeft(result)) {
      analytics.event('fail_accept_invite', {
        error: AppError.getCode(result.left),
      });
      return result.left;
    }

    setAccessToken(result.right.accessToken);
    analytics.event('complete_accept_invite');
    await router.replace({ name: AuthRouteName.Onboarding });

    return null;
  }

  async function verifyEmail(token: string): Promise<AppError | null> {
    const result = await authProvider.verifyEmailVerificationCode(token);

    if (isLeft(result)) {
      return result.left;
    }

    return null;
  }

  return {
    accountId,
    userId,
    accessToken,
    tokenIss,
    tokenTtl,
    userRole,
    lastEmail,

    isAuthenticated,
    isAdmin,

    singUp,
    signIn,
    signOut,
    startGoogleAuth,
    handleProviderAuth,
    refreshToken,
    isTokenExpired,
    verifyInvite,
    acceptInvite,
    verifyEmail,
    startPasswordReset,
    verifyResetPasswordToken,
    resetPassword,
    changePassword,
    reset,
  };
}, {
  persist: {
    key: 'cherry:auth',
    paths: ['lastEmail', 'accessToken'],
  },
});
