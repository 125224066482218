import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-flex" }
const _hoisted_2 = ["value", "min", "max"]


import { MinusIcon, PlusIcon } from '@heroicons/vue/24/outline';

interface Props {
  modelValue: number;
  minValue?: number;
  maxValue?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SeatsCounter',
  props: {
    modelValue: {},
    minValue: { default: 0 },
    maxValue: { default: 5000 }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const increment = () => {
  const count = props.modelValue + 1;

  if (props.maxValue && count > props.maxValue) {
    return;
  }

  emit('update:modelValue', count);
};

const decrement = () => {
  const count = props.modelValue - 1;

  if (count < props.minValue) {
    return;
  }

  emit('update:modelValue', count);
};

const handleInput = (e: InputEvent) => {
  const target = e.target as HTMLInputElement;
  target.value = target.value.replace(/\D+/g, '');
  const value = parseInt(target.value, 10);

  if (Number.isNaN(value)) {
    return;
  }

  if (value < props.minValue) {
    target.value = props.minValue.toString();
    emit('update:modelValue', props.minValue);
    return;
  }

  if (props.maxValue && value > props.maxValue) {
    target.value = props.maxValue.toString();
    emit('update:modelValue', props.maxValue);
    return;
  }

  emit('update:modelValue', value);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "h-7 w-7 px-1.5 py-1.5 ring-1 ring-gray-300 outline-none rounded-l bg-white enabled:hover:bg-gray-100 enabled:active:bg-gray-150",
      onClick: _withModifiers(decrement, ["prevent"])
    }, [
      _createVNode(_unref(MinusIcon), { class: "h-4 w-4 stroke-gray-800 stroke-2" })
    ]),
    _createElementVNode("input", {
      type: "number",
      class: "w-16 h-7 text-center text-gray-800 px-1.5 py-1 leading-none font-medium rounded-none border-none outline-none ring-1 ring-gray-300 focus:ring-gray-300",
      value: props.modelValue,
      min: props.minValue,
      max: props.maxValue,
      onInput: handleInput
    }, null, 40, _hoisted_2),
    _createElementVNode("button", {
      class: "h-7 w-7 px-1.5 py-1.5 ring-1 ring-gray-300 outline-none rounded-r bg-white enabled:hover:bg-gray-100 enabled:active:bg-gray-150",
      onClick: _withModifiers(increment, ["prevent"])
    }, [
      _createVNode(_unref(PlusIcon), { class: "h-4 w-4 stroke-gray-800 stroke-2" })
    ])
  ]))
}
}

})