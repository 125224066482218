import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-6 -mb-3 text-center text-sm text-red-500"
}
const _hoisted_2 = { class: "relative mt-5" }
const _hoisted_3 = { class: "relative flex justify-center text-sm leading-6" }
const _hoisted_4 = { class: "bg-white px-4 text-gray-500" }
const _hoisted_5 = { class: "mt-5" }
const _hoisted_6 = { class: "text-sm font-semibold leading-6" }
const _hoisted_7 = { class: "text-sm text-center mt-10" }

import { computed, ref, VueElement } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAuthStore } from '@/stores/auth';
import isEmail from 'validator/lib/isEmail';
import { AuthRouteName } from '@/router/route.names';
import { AppError, ErrorCode } from '@/lib/error';
import AuthPage from '@/components/Auth/AuthPage.vue';
import TextField from '@/components/Form/TextField.vue';
import PasswordField from '@/components/Form/PasswordField.vue';
import Button from '@/components/Core/Button.vue';
import Loader from '@/components/Core/Loader.vue';
import GoogleIcon from '@/components/Core/Icons/GoogleIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const { t } = useI18n({ useScope: 'global' });
const authStore = useAuthStore();

const loading = ref(false);
const googleLoading = ref(false);
const email = ref(authStore.lastEmail || '');
const password = ref('');

const emailError = ref<string>();
const passwordError = ref<string>();
const formError = ref<string>();

const emailField = ref<VueElement>();
const passwordField = ref<VueElement>();

const hasErrors = computed<boolean>(() => (
  !!emailError.value || !!passwordError.value
));

const handleEmailInput = (): void => {
  emailError.value = undefined;
};

const validateEmail = (): boolean => {
  email.value = email.value.trim();

  if (!email.value) {
    emailError.value = t('form.input.any.error.required');
  } else if (!isEmail(email.value)) {
    emailError.value = t('form.input.email.error.invalid');
  } else {
    emailError.value = undefined;
  }

  return !emailError.value;
};

const handleEmailChange = (byEnter: boolean): void => {
  const ok = validateEmail();

  if (byEnter && ok) {
    passwordField.value?.focus();
  }
};

const validatePassword = (): boolean => {
  password.value = password.value.trim();
  passwordError.value = !password.value ? t('form.input.any.error.required') : undefined;
  return !passwordError.value;
};

const handleSubmit = async (): Promise<void> => {
  if (loading.value) {
    return;
  }

  validateEmail();
  validatePassword();

  if (hasErrors.value) {
    return;
  }

  loading.value = true;
  formError.value = undefined;
  emailError.value = undefined;
  passwordError.value = undefined;

  const err = await authStore.signIn(email.value, password.value);

  switch (AppError.getCode(err)) {
    case null:
      break;
    case ErrorCode.Unauthorized:
    case ErrorCode.Invalid:
      formError.value = t('auth.login.error.invalid');
      break;
    default:
      formError.value = t('common.error.tech');
      break;
  }

  loading.value = false;
};

const handlePasswordInput = (): void => {
  passwordError.value = undefined;
};

const handlePasswordChange = (byEnter: boolean): void => {
  const ok = validatePassword();

  if (byEnter && ok) {
    handleSubmit();
  }
};

const handleGoogleSignIn = () => {
  const authUrl = authStore.startGoogleAuth(AuthRouteName.Login);
  googleLoading.value = true;

  setTimeout(() => {
    window.open(authUrl, '_self')?.focus();
    googleLoading.value = false;
  }, 200);
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createBlock(AuthPage, null, {
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('auth.login.title')), 1)
    ]),
    subtitle: _withCtx(() => [
      _createVNode(_component_i18n_t, {
        keypath: "auth.login.createAccount",
        tag: "span"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: { name: _unref(AuthRouteName).Signup },
            class: "link"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('auth.login.createAccount.link')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      })
    ]),
    form: _withCtx(() => [
      _createVNode(TextField, {
        modelValue: email.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
        ref_key: "emailField",
        ref: emailField,
        "input-type": "email",
        "label-text": _ctx.$t('form.input.emailAddress.label'),
        class: "mt-10",
        error: emailError.value,
        onInput: handleEmailInput,
        onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (handleEmailChange(true)), ["prevent"]), ["enter"])),
        onChange: _cache[2] || (_cache[2] = ($event: any) => (handleEmailChange(false)))
      }, null, 8, ["modelValue", "label-text", "error"]),
      _createVNode(PasswordField, {
        modelValue: password.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password).value = $event)),
        ref_key: "passwordField",
        ref: passwordField,
        class: "mt-6",
        "label-text": _ctx.$t('form.input.password.label'),
        error: passwordError.value,
        onInput: handlePasswordInput,
        onKeyup: _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => (handlePasswordChange(true)), ["prevent"]), ["enter"])),
        onChange: _cache[5] || (_cache[5] = ($event: any) => (handlePasswordChange(false)))
      }, null, 8, ["modelValue", "label-text", "error"]),
      (formError.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(formError.value), 1))
        : _createCommentVNode("", true),
      _createVNode(Button, {
        class: "w-full mt-10",
        onClick: handleSubmit,
        onKeyup: _withKeys(_withModifiers(handleSubmit, ["prevent"]), ["enter"])
      }, {
        default: _withCtx(() => [
          (loading.value)
            ? (_openBlock(), _createBlock(Loader, {
                key: 0,
                class: "text-white"
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('auth.login.btn.login')), 1)
              ], 64))
        ]),
        _: 1
      }, 8, ["onKeyup"]),
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createElementVNode("div", {
          class: "absolute inset-0 flex items-center",
          "aria-hidden": "true"
        }, [
          _createElementVNode("div", { class: "w-full border-t border-gray-200" })
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('common.text.or')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(Button, {
          class: "btn-bordered w-full",
          loading: googleLoading.value,
          onClick: handleGoogleSignIn
        }, {
          default: _withCtx(() => [
            _createVNode(GoogleIcon, { class: "w-5 h-5 mr-3" }),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)('auth.login.btn.signInWithGoogle')), 1)
          ]),
          _: 1
        }, 8, ["loading"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          to: { name: _unref(AuthRouteName).PasswordReset },
          class: "text-gray-600 hover:underline font-medium"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.login.forgotPassword')), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }))
}
}

})