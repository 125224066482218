import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "btn-content flex items-center" }

import {
  ref,
} from 'vue';
import Loader from '@/components/Core/Loader.vue';

interface Props {
  disabled?: boolean;
  loading?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  setup(__props: any, { expose: __expose }) {



const elem = ref<HTMLButtonElement>();
const focus = () => elem.value?.focus();
__expose({ focus });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled || _ctx.loading,
    ref_key: "elem",
    ref: elem,
    class: _normalizeClass(['btn', _ctx.loading && 'btn-loading'])
  }, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _withDirectives(_createVNode(Loader, null, null, 512), [
      [_vShow, _ctx.loading]
    ])
  ], 10, _hoisted_1))
}
}

})