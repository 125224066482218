import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center space-x-3 max-w-full" }
const _hoisted_2 = { class: "flex-auto min-w-0 mt-0.5" }
const _hoisted_3 = { class: "font-semibold text-sm text-white -mb-0.5 truncate" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { User } from '@/models';
import { AuthRouteName } from '@/router/route.names';
import Avatar from '@/components/User/Avatar.vue';

interface Props {
  user: User;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileInfo',
  props: {
    user: {}
  },
  setup(__props: any) {

const { t } = useI18n();
const props = __props;
const displayName = computed(() => props.user.name || props.user.email);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Avatar, {
      class: "shrink-0",
      size: "sm",
      seed: _ctx.user.id,
      "user-name": displayName.value
    }, null, 8, ["seed", "user-name"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(displayName.value), 1),
      _createVNode(_component_router_link, {
        to: { name: _unref(AuthRouteName).Logout },
        class: "text-xs text-green-500 hover:text-green-600"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('navigation.item.logout')), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}
}

})