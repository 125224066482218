import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-16 flex-shrink-0 items-center px-5 bg-back" }
const _hoisted_2 = { class: "flex flex-1 flex-col overflow-y-auto" }
const _hoisted_3 = { class: "flex flex-col min-h-screen lg:pl-64" }
const _hoisted_4 = { class: "sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white border-b" }
const _hoisted_5 = { class: "flex flex-1 justify-between px-4 sm:px-6 lg:px-10" }
const _hoisted_6 = { class: "min-w-0 flex flex-1 items-center" }
const _hoisted_7 = { class: "page-title text-2xl font-semibold leading-7 text-gray-900 sm:truncate" }
const _hoisted_8 = { class: "flex flex-1 h-full" }

import { FunctionalComponent, ref } from 'vue';
import { User } from '@/models';
import { Bars3BottomLeftIcon } from '@heroicons/vue/24/outline';
import Logo from '@/components/Core/Logo/Logo.vue';
import SidebarMenu from '@/components/Nav/SidebarMenu.vue';
import ProfileInfo from '@/components/User/ProfileInfo.vue';
import PageSidebar from '@/components/Layout/PageSidebar.vue';

interface NavItem {
  name: string;
  href: string;
}

interface SidebarNavItem extends NavItem {
  icon: FunctionalComponent;
}

interface Props {
  currentUser: User;
  pageTitle: string;
  sidebarNavigation: SidebarNavItem[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PageLayout',
  props: {
    currentUser: {},
    pageTitle: {},
    sidebarNavigation: {}
  },
  setup(__props: any) {



const sidebar = ref<typeof PageSidebar>();
const openSidebar = (): void => {
  sidebar.value?.open();
};
const closeSidebar = (): void => {
  sidebar.value?.close();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(PageSidebar, {
      ref_key: "sidebar",
      ref: sidebar
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(Logo, { class: "h-8" })
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(SidebarMenu, {
            navigation: _ctx.sidebarNavigation,
            class: "mt-5",
            onNavItemClick: _cache[0] || (_cache[0] = ($event: any) => (closeSidebar()))
          }, null, 8, ["navigation"])
        ]),
        _createVNode(ProfileInfo, {
          user: _ctx.currentUser,
          class: "mx-3 lg:mb-4 px-3 py-3 rounded-xl bg-white/10"
        }, null, 8, ["user"])
      ]),
      _: 1
    }, 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          type: "button",
          class: "border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-0 focus:text-gray-900 lg:hidden",
          onClick: openSidebar
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "sr-only" }, "Open sidebar", -1)),
          _createVNode(_unref(Bars3BottomLeftIcon), {
            class: "h-6 w-6",
            "aria-hidden": "true"
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.pageTitle), 1)
          ])
        ])
      ]),
      _createElementVNode("main", _hoisted_8, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}
}

})