import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-10 overflow-y-auto" }
const _hoisted_2 = { class: "flex min-h-full items-stretch justify-center p-0 pt-5 xs:pt-8 text-center sm:p-0 sm:pt-0 sm:items-center" }
const _hoisted_3 = { class: "absolute top-0 right-0 block pt-4 pr-4" }
const _hoisted_4 = { class: "sr-only" }

import { ref } from 'vue';
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalDialog',
  setup(__props, { expose: __expose }) {

const show = ref(false);
const open = () => { show.value = true; };
const close = () => { show.value = false; };

__expose({ open, close });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    as: "template",
    show: show.value
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Dialog), {
        as: "div",
        class: "relative z-10",
        onClose: close
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TransitionChild), {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(TransitionChild), {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                "enter-to": "opacity-100 translate-y-0 sm:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 sm:scale-100",
                "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogPanel), { class: "relative transform rounded-t-xl sm:rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg h-auto sm:p-6 ring-1 ring-gray-300" }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "default"),
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "rounded-md bg-transparent text-gray-400 hover:text-gray-500 active:text-gray-500 focus:outline-none",
                          onClick: close
                        }, [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('common.btn.close')), 1),
                          _createVNode(_unref(XMarkIcon), {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ])
                      ])
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              })
            ])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["show"]))
}
}

})