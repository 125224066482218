import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-10 overflow-y-auto" }
const _hoisted_2 = { class: "flex flex-1 flex-col min-h-full items-center justify-end p-0 text-center sm:justify-center" }
const _hoisted_3 = { class: "absolute top-0 right-0 hidden pt-4 pr-4 sm:block" }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "flex items-center h-full sm:items-start" }
const _hoisted_6 = { class: "flex flex-col mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left min-h-[18rem] sm:min-h-0 justify-around sm:justify-start" }
const _hoisted_7 = { class: "mt-4" }
const _hoisted_8 = { class: "text-sm text-gray-600" }
const _hoisted_9 = { class: "mt-6 space-y-4 sm:flex sm:space-x-3 sm:space-y-0" }
const _hoisted_10 = ["onKeyup"]
const _hoisted_11 = ["onKeyup"]


import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import Button from '@/components/Core/Button.vue';

interface Props {
  show: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmDialog',
  props: {
    show: { type: Boolean }
  },
  emits: ['result'],
  setup(__props: any, { emit: __emit }) {


const emit = __emit;

const confirm = () => {
  emit('result', true);
};

const cancel = () => {
  emit('result', false);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    as: "template",
    show: _ctx.show
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Dialog), {
        as: "div",
        class: "relative z-10",
        onClose: cancel
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TransitionChild), {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(TransitionChild), {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                "enter-to": "opacity-100 translate-y-0 sm:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 sm:scale-100",
                "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(DialogPanel), { class: "relative transform overflow-hidden rounded-t-xl sm:rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "rounded-md bg-white text-gray-400 hover:text-gray-500 active:text-gray-500 focus:outline-none",
                          onClick: cancel
                        }, [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('common.btn.close')), 1),
                          _createVNode(_unref(XMarkIcon), {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _cache[1] || (_cache[1] = _createElementVNode("img", {
                          src: "/icons/warning.svg",
                          alt: "icon",
                          class: "hidden sm:inline-block sm:w-5 sm:h-5"
                        }, null, -1)),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", null, [
                            _createVNode(_unref(DialogTitle), {
                              as: "h3",
                              class: "text-lg font-semibold leading-6 text-gray-900 sm:pr-2"
                            }, {
                              default: _withCtx(() => [
                                _renderSlot(_ctx.$slots, "title")
                              ]),
                              _: 3
                            }),
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("p", _hoisted_8, [
                                _renderSlot(_ctx.$slots, "text")
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", {
                              onClick: confirm,
                              onKeyup: _withKeys(_withModifiers(confirm, ["prevent"]), ["enter"])
                            }, [
                              _renderSlot(_ctx.$slots, "confirm", {}, () => [
                                _createVNode(Button, { class: "w-full sm:w-auto" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('common.btn.ok')), 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ], 40, _hoisted_10),
                            _createElementVNode("div", {
                              onClick: cancel,
                              onKeyup: _withKeys(_withModifiers(cancel, ["prevent"]), ["enter"])
                            }, [
                              _renderSlot(_ctx.$slots, "cancel", {}, () => [
                                _createVNode(Button, { class: "btn-bordered w-full sm:w-auto" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('common.btn.cancel')), 1)
                                  ]),
                                  _: 1
                                })
                              ])
                            ], 40, _hoisted_11)
                          ])
                        ])
                      ])
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              })
            ])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["show"]))
}
}

})