import { useGtag } from '@/composables/gtag';

export const useAnalytics = () => {
  const gtag = useGtag();

  const sendEvent = (event: string, params?: Record<string, unknown>) => {
    gtag('event', event, params);
  };

  return {
    event: sendEvent,
  };
};
