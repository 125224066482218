import UAParser from 'ua-parser-js';

type DeviceType = 'mobile' | 'tablet' | 'desktop' | 'smarttv' | 'console' | 'wearable' | 'embedded';

export function useDeviceType(): DeviceType {
  const parser = new UAParser();
  return (parser.getDevice().type || 'desktop') as DeviceType;
}

export function useBrowserName(): string {
  const parser = new UAParser();
  return (parser.getBrowser().name || '').toLowerCase();
}
